import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  IconButton,
  MenuItem,
  InputAdornment,
  Alert,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SearchIcon from '@mui/icons-material/Search';
import backgroundImage from '../../assets/images/girls-dancing-club.jpg';

const BackgroundContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(2),
  width: '100%',
  maxWidth: '100%',
  margin: 0,
}));

const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
  borderRadius: '16px',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '500px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    fontSize: '1.2rem',
  },
  '& .MuiInputBase-input': {
    fontSize: '1rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: '#fff',
  fontSize: '1rem',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
  },
}));

const AvatarInput = styled('input')({
  display: 'none',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  margin: 'auto',
  marginBottom: theme.spacing(2),
}));

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    profile_picture: null,
    date_of_birth: '',
    location: '',
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const { registerUser } = useContext(AuthContext) || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const allowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com'];

  const validateEmailDomain = (email) => {
    const domain = email.split('@')[1];
    return allowedDomains.includes(domain);
  };

  const validateStep = (step) => {
    switch (step) {
      case 0:
        if (!formData.username || !formData.email || !formData.password) {
          setError('Please fill all required fields.');
          return false;
        }
        if (!validateEmailDomain(formData.email)) {
          setError('Only Gmail, Yahoo, and Outlook email addresses are allowed.');
          return false;
        }
        break;
      case 1:
        if (!formData.date_of_birth || !formData.location) {
          setError('Please fill all required fields.');
          return false;
        }
        const birthDate = new Date(formData.date_of_birth);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 18) {
          setError('You must be at least 18 years old to register.');
          return false;
        }
        break;
      default:
        return true;
    }
    setError('');
    return true;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      try {
        const formDataToSend = new FormData();
        for (const key in formData) {
          formDataToSend.append(key, formData[key]);
        }
        await registerUser(formDataToSend);
        console.log('Registration successful');
        navigate('/login');
      } catch (error) {
        console.error('Registration failed:', error);
        setError('Registration failed. Please try again.');
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        profile_picture: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const states = ['New York', 'California', 'Texas', 'Florida', 'Illinois']; // Example list of states

  const filteredStates = states.filter((state) =>
    state.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const steps = ['Basic Info', 'Additional Details'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box sx={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
              <StyledAvatar src={previewUrl} />
              <label htmlFor="icon-button-file">
                <AvatarInput
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <IconButton 
                  color="primary" 
                  aria-label="upload picture" 
                  component="span"
                  sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    right: 0, 
                    backgroundColor: 'white' 
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </label>
            </Box>
            <StyledTextField
              variant="outlined"
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              fullWidth
            />
            <StyledTextField
              variant="outlined"
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              fullWidth
            />
            <StyledTextField
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
              fullWidth
            />
          </>
        );
      case 1:
        return (
          <>
            <StyledTextField
              variant="outlined"
              label="Date of Birth"
              name="date_of_birth"
              type="date"
              value={formData.date_of_birth}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            />
            <StyledTextField
              select
              variant="outlined"
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              fullWidth
              required
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            >
              {filteredStates.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BackgroundContainer maxWidth={false}>
      <StyledCard component="form" onSubmit={handleSubmit}>
        <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
          Register
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: theme.spacing(3) }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {getStepContent(activeStep)}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <StyledButton type="submit">
              Register
            </StyledButton>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </Box>
      </StyledCard>
    </BackgroundContainer>
  );
};

export default Register;
