import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Button, Grid, Card, CardContent, List, ListItem, 
  ListItemText, Divider, Avatar, Box, Paper, Tabs, Tab, Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getTransactions, getUserProfile, getPayoutRequests, getPaymentSettings } from '../../services/api';
import CoinIcon from '../../assets/images/Coins.png';

const ModelWallet = () => {
  const [transactions, setTransactions] = useState([]);
  const [payoutRequests, setPayoutRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [payoutRate, setPayoutRate] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [transactionResponse, userProfileResponse, payoutRequestsResponse, paymentSettingsResponse] = await Promise.all([
        getTransactions(),
        getUserProfile(),
        getPayoutRequests(),
        getPaymentSettings()
      ]);
      
      // Check if user is not a model
      if (!userProfileResponse.data.user_type==='regular') {
        navigate('/MyWallet');
        return;
      }
      
      setTransactions(transactionResponse.data);
      setWalletBalance(userProfileResponse.data.wallet_balance);
      setPayoutRequests(payoutRequestsResponse.data);

      // Set payout rate from API
      setPayoutRate(paymentSettingsResponse.data.payout_rate || 0);
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handlePayout = () => {
    const amountInCoins = parseInt(amount, 10);
    if (isNaN(amountInCoins) || amountInCoins < 2500) {
      setError('Minimum payout is 2500 coins');
      return;
    }
    if (amountInCoins > walletBalance) {
      setError('Insufficient balance');
      return;
    }
    // Here you would typically call an API to request a payout
    setSuccess(`Payout request for ${amountInCoins} coins (₹${(amountInCoins * payoutRate).toFixed(2)}) has been submitted`);
    setAmount('');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTransactions = () => (
    <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
      {transactions.length > 0 ? (
        transactions.map((transaction) => (
          <React.Fragment key={transaction.id}>
            <ListItem>
              <Avatar src={CoinIcon} sx={{ width: 24, height: 24, mr: 2 }} />
              <ListItemText
                primary={`${transaction.amount} Coins`}
                secondary={`Description: ${transaction.description} | ${new Date(transaction.timestamp).toLocaleString()}`}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No transactions found" />
        </ListItem>
      )}
    </List>
  );

  const renderPayoutRequests = () => (
    <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
      {payoutRequests.length > 0 ? (
        payoutRequests.map((request) => (
          <React.Fragment key={request.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography>
                    Amount: ₹{(request.amount * payoutRate).toFixed(2)} ({request.amount} Coins)
                  </Typography>
                }
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      Status: {request.status}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      Date: {new Date(request.created_at).toLocaleString()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No payout requests found" />
        </ListItem>
      )}
    </List>
  );

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mb: 2, borderRadius: '12px', marginTop:'20px' }}>
        <Typography variant="h5" gutterBottom>
          Model Wallet Balance
        </Typography>
        <Box display="flex" alignItems="center">
          {loading ? (
            <>
              <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
              <Skeleton variant="text" width={100} height={40} />
            </>
          ) : (
            <>
              <Avatar src={CoinIcon} sx={{ width: 40, height: 40, mr: 2 }} />
              <Typography variant="h4" color="primary" gutterBottom>
                {walletBalance} Coins
              </Typography>
            </>
          )}
        </Box>
      </Paper>

      {loading ? (
        <Skeleton variant="text" width={300} height={24} />
      ) : (
        <Typography variant="body1" paragraph>
          Current payout rate: ₹{payoutRate} per coin
          <br />
          Minimum payout: 2500 coins (₹{(2500 * payoutRate).toFixed(2)})
        </Typography>
      )}

      <Card variant="outlined" sx={{ mb: 2, borderRadius: '12px', overflow: 'hidden' }}>
        <CardContent>
          <Typography variant="h6">Request Payout</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={48} />
              ) : (
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter coins (min 2500)"
                  style={{ 
                    width: '100%', 
                    padding: '12px', 
                    borderRadius: '4px', 
                    border: 'none', 
                    borderBottom: '2px solid #ddd', 
                    boxShadow: 'none', 
                    outline: 'none',
                    marginBottom: '16px',
                    fontSize: '16px',
                    boxSizing: 'border-box'
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={48} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePayout}
                  sx={{ width: '100%', borderRadius: '8px' }}
                >
                  Request Payout
                </Button>
              )}
            </Grid>
          </Grid>
          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
          {success && <Typography color="success" sx={{ mt: 2 }}>{success}</Typography>}
        </CardContent>
      </Card>

      {loading ? (
        <Box>
          <Tabs value={0} sx={{ mb: 2 }}>
            <Tab label={<Skeleton variant="text" width={120} height={32} />} />
            <Tab label={<Skeleton variant="text" width={120} height={32} />} />
          </Tabs>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
      ) : (
        <>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Transaction History" />
            <Tab label="Payout Requests" />
          </Tabs>

          {activeTab === 0 && renderTransactions()}
          {activeTab === 1 && renderPayoutRequests()}
        </>
      )}
    </Container>
  );
};

export default ModelWallet;