import React from 'react';
import { Box, Button, Typography, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIcon from '@mui/icons-material/Phone';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useNavigate } from 'react-router-dom'; // If using React Router

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  zIndex: 1300,
}));

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '46%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 280,
  backgroundColor: '#1f1f1f',
  boxShadow: theme.shadows[8],
  padding: theme.spacing(3),
  paddingTop:'50px',
  zIndex: 1301,
  borderRadius: 12,
  color: '#fff',
}));

const TopContainer = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.main}`,
  borderRadius: 12,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: '#2a2a2a',
}));

const MenuItem = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(1, 0),
  color: '#fff',
  '& svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const PopupMobileMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); // React Router hook

  const handleLoginClick = () => {
    onClose();
    navigate('/login'); // Redirect to login page
  };

  const handleSignUpClick = () => {
    onClose();
    navigate('/signup'); // Redirect to signup page
  };

  const handleBecomeModelClick = () => {
    onClose();
    navigate('/register/model'); // Redirect to model registration page
  };

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <IconButton
          sx={{ position: 'absolute',
            top: '5px',
            right: '16px',
            color: '#3B2122',
            padding:'3px',
            backgroundColor: '#FFDF85',
            '&:hover': {
              backgroundColor: '#FFD452',
            }, }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
        <TopContainer>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 3, backgroundColor: '#f39c12', '&:hover': { backgroundColor: '#e67e22' } }}
            onClick={handleSignUpClick}
          >
            Create New Account
          </Button>
          <MenuItem>
            <ChatIcon /> Chat with models
          </MenuItem>
          <MenuItem>
            <PhoneIcon /> Have fun on Call
          </MenuItem>
          <MenuItem>
            <CardGiftcardIcon /> Take part in giveaways
          </MenuItem>
        </TopContainer>
        <Button
          variant="outlined"
          fullWidth
          sx={{ mb: 2, borderColor: '#fff', color: '#fff' }}
          onClick={handleLoginClick}
        >
          Login
        </Button>
        <MenuItem>
          <PeopleIcon /> Top Models
        </MenuItem>
        <MenuItem>
          <InfoIcon /> About UlluChat
        </MenuItem>
        <MenuItem onClick={handleBecomeModelClick}>
          <ModelTrainingIcon /> Become a Model
        </MenuItem>
        <MenuItem>
          <LibraryBooksIcon /> Blogs
        </MenuItem>
      </PopupContainer>
    </Overlay>
  );
};

export default PopupMobileMenu;
