import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import ModelCard from '../../Components/ModelCards'; // Ensure correct import path
import { getModels } from '../../services/api';

const Home = () => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await getModels();
        setModels(response.data);
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load models. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  return (
    <Container maxWidth="xl">
      {loading && !error ? (
        <Grid container spacing={3}>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ModelCard loading />
            </Grid>
          ))}
        </Grid>
      ) : error ? (
        <Box textAlign="center" my={4}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {models.map((model) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={model.id}>
              <ModelCard model={model} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Home;
