import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Rating,
  TextField,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import styled from 'styled-components';
import { rateModel } from '../services/api'; // Adjust the path as needed

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 12px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  background-color: #A2252D;
  color: white;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const StyledRating = styled(Rating)`
  font-size: 48px;
  margin: 16px 0;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 16px 24px;
`;

const RatingPopup = ({ open, onClose, onSubmit, modelId, chatId }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async () => {
    if (rating === 0) {
      setError('Please select a rating.');
      return;
    }
  
    setIsLoading(true);
    try {
      await rateModel(modelId, chatId, rating, review);
      onSubmit();
      onClose();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting rating:', error);
      setError('Failed to submit rating. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>Rate Your Chat Experience</StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="h6" component="div">
          How would you rate your experience with this chat?
        </Typography>
        <StyledRating
          name="rating"
          value={rating}
          onChange={(event, newValue) => setRating(newValue)}
          size="large"
        />
        <TextField
          label="Review (optional)"
          multiline
          minRows={3}
          value={review}
          onChange={(e) => setReview(e.target.value)}
          fullWidth
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">
          Skip
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isLoading || rating === 0}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </StyledDialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Rating submitted successfully!"
      />
    </StyledDialog>
  );
};

export default RatingPopup;
