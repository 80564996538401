import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Chip, 
  Grid, 
  Skeleton 
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import MoodIcon from '@mui/icons-material/Mood';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIcon from '@mui/icons-material/Phone';
import CoinIcon from '../assets/images/Coins.png';
import PlaceholderAvatar from '../assets/images/placeholder_avatar.jpg';
import ModelInfoPopup from './ModelInfoPopup';
import { useUserStatus } from '../contexts/UserStatusContext';
import { useNavigate } from 'react-router-dom';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  cursor: 'pointer',
}));

const ImageSection = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

const ProfileImage = styled('img')(({ theme }) => ({
  width: '90%',
  aspectRatio: '3 / 5',
  borderRadius: '7px',
  objectFit: 'cover',
  marginBottom: theme.spacing(2),
}));

const ContentSection = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

const FooterSection = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[50],
}));

const StyledName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
}));

const CoinIconImg = styled('img')({
  width: 20,
  height: 20,
  marginRight: 4,
});

const OnlineStatus = styled(Box)(({ theme, isOnline }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: isOnline ? theme.palette.success.main : theme.palette.grey[500],
  marginRight: theme.spacing(1),
}));

const ModelCard = ({ model, loading }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [popupFieldName, setPopupFieldName] = useState('');
  const { isUserOnline } = useUserStatus();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Box my={2}>
        <StyledCard>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={5}>
              <ImageSection>
                <Skeleton variant="rectangular" width="90%" height="200px" />
              </ImageSection>
            </Grid>
            <Grid item xs={7}>
              <ContentSection>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="rectangular" width="80%" height={24} style={{ marginTop: 8 }} />
              </ContentSection>
            </Grid>
          </Grid>
          <FooterSection>
            <Skeleton variant="rectangular" width="30%" height={24} />
            <Skeleton variant="rectangular" width="30%" height={24} />
          </FooterSection>
        </StyledCard>
      </Box>
    );
  }

  if (!model) {
    return null;
  }

  const { id, first_name, last_name, profile_picture, location, profile } = model;
  const { chat_price, audio_price, languages, fantasy, mood, relationship_status, rating, total_ratings } = profile;

  const isOnline = isUserOnline(id);

  const handlePopupOpen = (data, fieldName) => {
    setPopupData(data);
    setPopupFieldName(fieldName);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleCardClick = () => {
    navigate(`/model/profile/details/${id}`);
  };

  return (
    <Box my={2}>
      <StyledCard onClick={handleCardClick}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={5}>
            <ImageSection>
              <Box position="relative" width="90%">
                <ProfileImage
                  src={profile_picture ? `${process.env.REACT_APP_API_URL}${profile_picture}` : PlaceholderAvatar}
                  alt={first_name || 'Model'}
                />
                <Box
                  position="absolute"
                  bottom={8}
                  left={0}
                  right={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="#fff"
                  borderRadius={'21px'}
                  py={0.5}
                  boxShadow={'0 8px 24px rgba(0, 0, 0, 0.15)'}
                >
                  <StarIcon color="primary" fontSize="small" />
                  <Typography variant="body2" color="black" ml={0.5}>
                    {rating || 'N/A'} ({total_ratings} ratings)
                  </Typography>
                </Box>
              </Box>
            </ImageSection>
          </Grid>
          <Grid item xs={7}>
            <ContentSection>
              <Box display="flex" alignItems="center" mb={1}>
                <OnlineStatus isOnline={isOnline} />
                <StyledName variant="h6">
                  {first_name} {last_name || 'Unknown Model'}
                </StyledName>
                <Typography variant="body2" color="text.secondary" ml={1}>
                  {isOnline ? 'Online' : 'Offline'}
                </Typography>
              </Box>
              <InfoItem>
                <LocationOnIcon fontSize="small" color="action" />
                <Typography variant="body2" color="text.secondary" ml={0.5}>
                  {location || 'N/A'}
                </Typography>
              </InfoItem>
              <InfoItem>
                <LanguageIcon fontSize="small" color="action" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  ml={0.5}
                  onClick={(e) => { e.stopPropagation(); handlePopupOpen(languages, 'Languages'); }}
                  style={{ cursor: 'pointer' }}
                >
                  {languages.length > 2 ? `Languages (+${languages.length - 2}) more` : languages.join(', ') || 'Languages not specified'}
                </Typography>
              </InfoItem>
              <InfoItem>
                <MoodIcon fontSize="small" color="action" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  ml={0.5}
                  onClick={(e) => { e.stopPropagation(); handlePopupOpen(mood, 'Mood'); }}
                  style={{ cursor: 'pointer' }}
                >
                  {mood.length > 2 ? `Mood (+${mood.length - 2}) more` : mood.join(', ') || 'Mood not specified'}
                </Typography>
              </InfoItem>
              <InfoItem>
                <FavoriteIcon fontSize="small" color="action" />
                <Typography variant="body2" color="text.secondary" ml={0.5}>
                  {relationship_status || 'Relationship status not specified'}
                </Typography>
              </InfoItem>
              <Box mt={1}>
                {fantasy.length > 2 ? (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    onClick={(e) => { e.stopPropagation(); handlePopupOpen(fantasy, 'Fantasy'); }}
                    style={{ cursor: 'pointer' }}
                  >
                    {`Fantasy (+${fantasy.length - 2}) more`}
                  </Typography>
                ) : (
                  fantasy.map((item, index) => (
                    <Chip key={index} label={item} size="small" variant="outlined" style={{ marginRight: 4 }} />
                  ))
                )}
              </Box>
            </ContentSection>
          </Grid>
        </Grid>
        <FooterSection>
          <Box display="flex" alignItems="center">
            <ChatIcon color="primary" fontSize="small" />
            <CoinIconImg src={CoinIcon} alt="Coins" />
            <Typography variant="body2">{chat_price}/min</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PhoneIcon color="secondary" fontSize="small" />
            <CoinIconImg src={CoinIcon} alt="Coins" />
            <Typography variant="body2">{audio_price}/min</Typography>
          </Box>
        </FooterSection>
      </StyledCard>

      <ModelInfoPopup
        open={popupOpen}
        onClose={handlePopupClose}
        data={popupData}
        fieldName={popupFieldName}
      />
    </Box>
  );
};

export default ModelCard;