import React, { useEffect, useState } from 'react';
import { Typography, Box, Card, CardContent, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ThankYou = ({ amount }) => {
  const [redirectTimer, setRedirectTimer] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setRedirectTimer((prevTime) => prevTime - 1);
    }, 1000);

    if (redirectTimer === 0) {
      clearInterval(timer);
      navigate('/'); // Redirect to home
    }

    return () => clearInterval(timer);
  }, [redirectTimer, navigate]);

  return (
    <Card variant="outlined" sx={{ mt: 4, borderRadius: '16px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
      <CardContent sx={{ padding: '32px', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
          Thank You!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your payment of ₹{amount.toFixed(2)} has been successfully processed.
        </Typography>
        <Box mt={4} position="relative" display="inline-block">
          <CircularProgress 
            size={60} 
            thickness={4} 
            variant="determinate" 
            value={(5 - redirectTimer) / 5 * 100} 
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body1" component="div" fontWeight="bold">
              {redirectTimer}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" mt={2}>
          Redirecting to home in {redirectTimer} seconds...
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ThankYou;