import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
    Avatar,
    IconButton,
    Button,
    Typography,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LockIcon from '@mui/icons-material/Lock';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SecurityIcon from '@mui/icons-material/Security';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import chatBackgroundImage from '../../assets/images/Chatbg.png';
import { useChat } from '../../contexts/ChatContext';
import { useUserStatus } from '../../contexts/UserStatusContext';
import { getUserDetails } from '../../services/api';
import RatingPopup from '../../Components/RatingPopup';


const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    
    @media (min-width: 768px) {
        max-width: 800px;
        height: 90vh;
    }
`;

const ChatHeader = styled.div`
    background-color: #A2252D;
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TimerText = styled(Typography)`
    color: white;
    margin-right: 10px;
`;

const ParticipantInfo = styled.div`
    display: flex;
    align-items: center;
`;

const ParticipantDetails = styled.div`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
`;

const ParticipantName = styled(Typography).attrs({ variant: 'h6' })`
    font-size: 1rem;
    
    @media (min-width: 768px) {
        font-size: 1.25rem;
    }
`;

const StatusText = styled(Typography).attrs({ variant: 'body2' })`
    color: #FFDADA;
`;

const MessageList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-image: url(${chatBackgroundImage});
    background-size: cover;
    background-position: center;
`;

const EncryptionMessage = styled(Typography).attrs({ variant: 'body2' })`
    background-color: rgba(241, 241, 241, 0.9);
    color: #333;
    padding: 12px 20px;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 0.9rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const EncryptionIcon = styled(LockIcon)`
    color: #4CAF50;
    font-size: 1.5rem;
    margin-bottom: 4px;
`;

const ChatIdText = styled(Typography).attrs({ variant: 'caption' })`
    color: #666;
    margin-top: 4px;
`;

const Message = styled.div`
    max-width: 70%;
    padding: 12px 30px;
    margin-bottom: 25px;
    border-radius: 21px;
    background-color: ${props => props.isMine ? '#A2252D' : 'rgba(255, 255, 255, 0.9)'};
    color: ${props => props.isMine ? 'white' : 'black'};
    align-self: ${props => props.isMine ? 'flex-end' : 'flex-start'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    word-wrap: break-word;
`;

const MessageTimestamp = styled.span`
    font-size: 0.7rem;
    font-weight: 500;
    color: ${props => props.isMine ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
    position: absolute;
    bottom: -18px;
    ${props => props.isMine ? 'right: 5px;' : 'left: 5px;'}
`;

const InputArea = styled.div`
    display: flex;
    padding: 15px;
    background-color: white;
    border-top: 1px solid #e0e0e0;
    position: relative;
`;

const Input = styled.input`
    flex: 1;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    margin-right: 10px;
    outline: none;

    &:focus {
        border-color: #A2252D;
    }
`;

const SendButton = styled(IconButton)`
    && {
        background-color: #A2252D;
        color: white;
        padding: 10px;

        &:hover {
            background-color: #8a1e25;
        }

        &:disabled {
            background-color: #d3d3d3;
        }
    }
`;

const EmojiButton = styled(IconButton)`
    && {
        padding: 8px;
        color: #666;
    }
`;

const EndChatButton = styled(Button)`
    && {
        color: #fff;
        border: 1px solid #FFDADA;
        border-radius: 20px;
        padding: 5px 15px;
        font-size: 14px;
        text-transform: none;
        margin-left: 10px;
    }
`;

const TypingIndicator = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    align-self: flex-start;
    margin-bottom: 15px;
`;

const TypingDot = styled.span`
    width: 8px;
    height: 8px;
    background-color: #A2252D;
    border-radius: 50%;
    margin: 0 2px;
    display: inline-block;
    animation: typing 1.4s infinite ease-in-out both;

    &:nth-child(1) { animation-delay: -0.32s; }
    &:nth-child(2) { animation-delay: -0.16s; }

    @keyframes typing {
        0%, 80%, 100% { transform: scale(0); }
        40% { transform: scale(1); }
    }
`;

const EmojiPickerContainer = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1000;
`;

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 16px 16px 0 0;
        margin: 0;
        max-width: 100%;
        width: 100%;
        position: fixed;
        bottom: 0;
    }
`;

const DialogHeader = styled(DialogTitle)`
    background-color: #A2252D;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledList = styled(List)`
    padding: 16px;
`;

const StyledListItem = styled(ListItem)`
    margin-bottom: 16px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
    color: #A2252D;
`;

const ChatRoom = () => {
    const [inputMessage, setInputMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [participant, setParticipant] = useState({});
    const [timer, setTimer] = useState(0);
    const [showEncryptionInfo, setShowEncryptionInfo] = useState(false);
    const [showRatingPopup, setShowRatingPopup] = useState(false);

    const { chatId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const messageListRef = useRef(null);
    const inputRef = useRef(null);
    const emojiPickerRef = useRef(null);

    const {
        currentChat,
        messages,
        chatStatus,
        sendMessage,
        endChat,
        partnerIsTyping,
        sendTypingStatus,
        socketReady,
        currentUser
    } = useChat();

    const { isUserOnline } = useUserStatus();

    const isParticipantOnline = useCallback(() => {
        return isUserOnline(participant.id);
    }, [isUserOnline, participant.id]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userId = location.state?.userId || location.state?.modelId;
                if (userId) {
                    const response = await getUserDetails(userId);
                    setParticipant(response.data);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchUserDetails();
    }, [location.state?.userId, currentChat?.modelId]);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (chatStatus === 'ended') {
            setShowRatingPopup(true);
        }
    }, [chatStatus]);

    useEffect(() => {
        let interval;
        if (chatStatus === 'active') {
            interval = setInterval(() => {
                setTimer(prev => prev + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [chatStatus]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSendMessage = useCallback(() => {
        if (inputMessage.trim() !== '' && currentChat) {
            sendMessage(inputMessage);
            setInputMessage('');
            sendTypingStatus(false);
        }
    }, [inputMessage, currentChat, sendMessage, sendTypingStatus]);

    const handleEndChat = useCallback(() => {
        endChat();
        localStorage.removeItem('chatId');
    }, [endChat]);

    const handleRatingSubmit = useCallback(() => {
        setShowRatingPopup(false);
        navigate('/');
    }, [navigate]);

    const handleEmojiSelect = useCallback((emoji) => {
        setInputMessage(prevMessage => prevMessage + emoji.native);
        inputRef.current.focus();
    }, []);

    const toggleEmojiPicker = useCallback(() => {
        setShowEmojiPicker(prev => !prev);
    }, []);

    const handleInputChange = useCallback((e) => {
        const newMessage = e.target.value;
        setInputMessage(newMessage);
        sendTypingStatus(newMessage.trim() !== '');
    }, [sendTypingStatus]);

    const handleInputBlur = useCallback(() => {
        sendTypingStatus(false);
    }, [sendTypingStatus]);

    const formatTimestamp = useCallback((timestamp) => {
        return (new Date(timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }, []);

    const formatTime = useCallback((seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }, []);

    const handleOpenEncryptionInfo = useCallback(() => {
        setShowEncryptionInfo(true);
    }, []);

    const handleCloseEncryptionInfo = useCallback(() => {
        setShowEncryptionInfo(false);
    }, []);

    return (
        <ChatContainer>
            <ChatHeader>
                <ParticipantInfo>
                    <Avatar alt={participant?.first_name} src={participant?.avatar} />
                    <ParticipantDetails>
                        <ParticipantName>{`${participant?.first_name} ${participant?.last_name}`}</ParticipantName>
                        <StatusText>
                            {isParticipantOnline() ? 'Online' : 'Offline'}
                        </StatusText>
                    </ParticipantDetails>
                </ParticipantInfo>
                <TimerText variant="body2">{formatTime(timer)}</TimerText>
                <EndChatButton variant="outlined" onClick={handleEndChat}>
                    End Chat
                </EndChatButton>
            </ChatHeader>
            <MessageList ref={messageListRef}>
                <EncryptionMessage>
                    <EncryptionIcon />
                    <Typography variant="subtitle2" fontWeight="bold">End-to-End Encrypted</Typography>
                    <Typography>
                        Your messages are secured with end-to-end encryption technology.
                    </Typography>
                    <Tooltip title="Learn more about our encryption" arrow>
                        <IconButton size="small" color="primary" onClick={handleOpenEncryptionInfo}>
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <ChatIdText>Chat ID: {chatId}</ChatIdText>
                </EncryptionMessage>
                {messages.map((msg, index) => (
                    <Message key={index} isMine={msg.sender_id === currentUser.id.toString()}>
                        {msg.content}
                        <MessageTimestamp isMine={msg.sender_id === currentUser.id.toString()}>
                            {formatTimestamp(msg.timestamp)}
                        </MessageTimestamp>
                    </Message>
                ))}
                {partnerIsTyping && (
                    <TypingIndicator>
                        <TypingDot />
                        <TypingDot />
                        <TypingDot />
                    </TypingIndicator>
                )}
            </MessageList>
            <InputArea>
                <EmojiButton onClick={toggleEmojiPicker}>
                    <EmojiEmotionsIcon />
                </EmojiButton>
                {showEmojiPicker && (
                    <EmojiPickerContainer ref={emojiPickerRef}>
                        <Picker
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                            theme="light"
                            emojiSize={20}
                            emojiButtonSize={28}
                            width="300px"
                            height="400px"
                            previewPosition="none"
                            skinTonePosition="search"
                        />
                    </EmojiPickerContainer>
                )}
                <Input
                    value={inputMessage}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder="Type a message"
                    ref={inputRef}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                />
                <SendButton onClick={handleSendMessage} disabled={!socketReady || chatStatus !== 'active' || inputMessage.trim() === ''}>
                    <SendIcon />
                </SendButton>
            </InputArea>
            <StyledDialog
                open={showEncryptionInfo}
                onClose={handleCloseEncryptionInfo}
                fullWidth
                maxWidth="sm"
            >
                <DialogHeader>
                    <Typography variant="h6">How End-to-End Encryption Works</Typography>
                    <IconButton edge="end" color="inherit" onClick={handleCloseEncryptionInfo} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogHeader>
                <DialogContent>
                    <StyledList>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <VpnKeyIcon fontSize="large" />
                            </StyledListItemIcon>
                            <ListItemText
                                primary="1. Key Generation"
                                secondary="When you start a chat, unique encryption keys are generated for you and the recipient."
                            />
                        </StyledListItem>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <SecurityIcon fontSize="large" />
                            </StyledListItemIcon>
                            <ListItemText
                                primary="2. Message Encryption"
                                secondary="Your messages are encrypted on your device before being sent. Only the recipient's key can decrypt them."
                            />
                        </StyledListItem>
                        <StyledListItem>
                            <StyledListItemIcon>
                                <VisibilityOffIcon fontSize="large" />
                            </StyledListItemIcon>
                            <ListItemText
                                primary="3. Secure Transmission"
                                secondary="Messages remain encrypted during transmission. Not even our servers can read your messages."
                            />
                        </StyledListItem>
                    </StyledList>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEncryptionInfo} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </StyledDialog>

            <RatingPopup 
                open={showRatingPopup} 
                onClose={() => setShowRatingPopup(false)} 
                onSubmit={handleRatingSubmit} 
                modelId={currentChat?.modelId}
                chatId={chatId} 
            />
        </ChatContainer>
    );
};

export default ChatRoom;