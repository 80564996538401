import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import ChatNotificationPopup from '../Components/ChatNotificationPopup';
import { useChat } from './ChatContext';

const ChatNotificationContext = createContext();

export const ChatNotificationProvider = ({ children }) => {
    const [chatRequests, setChatRequests] = useState([]);
    const [notificationSocket, setNotificationSocket] = useState(null);
    const [socketReady, setSocketReady] = useState(false);
    
    const { setChatStatus, setCurrentChat } = useChat();

    const handleIncomingNotification = useCallback((data) => {
        console.log('Handling incoming notification:', data);

        switch (data.type) {
            case 'chat_request':
                console.log('Chat request received:', data);
                setChatRequests(prevRequests => [...prevRequests, data]);
                break;
            case 'chat_response_notification':
                console.log('Chat response notification received:', data);
                setChatRequests(prevRequests => prevRequests.filter(request => request.chat_id !== data.chat_id));
                if (data.status !== 'active') {
                    setChatStatus('rejected');
                }
                break;
            case 'chat_response_processed':
                console.log('Chat response processed:', data);
                if (data.status === 'active') {
                    console.log('Chat is now active:', data.chat_id);
                    setChatStatus('active');
                    setCurrentChat({
                        id: data.chat_id,
                    });
                } else {
                    console.warn(`Chat not active. Status received: ${data.status}`);
                }
                break;
            case 'chat_ended':
                console.log('Chat has ended:', data.chat_id);
                setChatStatus('ended');
                setCurrentChat(null);
                break;
            case 'error':
                console.error('Error notification received:', data.message);
                setChatStatus('error');
                break;
            default:
                console.warn('Unknown notification type:', data.type);
        }
    }, [setChatStatus, setCurrentChat]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const newNotificationSocket = new WebSocket(`wss://www.kookutalk.com/ws/chat_notifications/?token=${token}`);

            newNotificationSocket.onopen = () => {
                console.log('Connected to chat notification WebSocket');
                setSocketReady(true);
            };

            newNotificationSocket.onmessage = (event) => {
                console.log('Raw WebSocket message:', event.data);
                const data = JSON.parse(event.data);
                console.log('Parsed WebSocket message:', data);
                handleIncomingNotification(data);
            };

            newNotificationSocket.onclose = () => {
                console.log('Disconnected from chat notification WebSocket');
                setSocketReady(false);
            };

            newNotificationSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
                setSocketReady(false);
            };

            setNotificationSocket(newNotificationSocket);

            return () => {
                if (newNotificationSocket) {
                    newNotificationSocket.close();
                }
            };
        }
    }, [handleIncomingNotification]);

    const respondToChatRequest = useCallback((chatId, response) => {
        if (notificationSocket && socketReady) {
            notificationSocket.send(JSON.stringify({
                type: 'chat_response',
                chat_id: chatId,
                response: response,
            }));
            console.log('Responded to chat request:', { chatId, response });
            setChatRequests(prevRequests => prevRequests.filter(request => request.chat_id !== chatId));
        } else {
            console.warn('Cannot respond to chat request: WebSocket is not connected.');
        }
    }, [notificationSocket, socketReady]);

    const value = {
        chatRequests,
        respondToChatRequest,
        socketReady,
    };

    return (
        <ChatNotificationContext.Provider value={value}>
            {children}
            <ChatNotificationPopup />
        </ChatNotificationContext.Provider>
    );
};

export const useChatNotification = () => {
    const context = useContext(ChatNotificationContext);
    if (!context) {
        throw new Error('useChatNotification must be used within a ChatNotificationProvider');
    }
    return context;
};

export default ChatNotificationContext;
