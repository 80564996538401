import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [chatSocket, setChatSocket] = useState(null);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [chatStatus, setChatStatus] = useState('idle');
    const [isTyping, setIsTyping] = useState(false);
    const [partnerIsTyping, setPartnerIsTyping] = useState(false);
    const [socketReady, setSocketReady] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setCurrentUser(JSON.parse(storedUser));
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            const token = localStorage.getItem('token');
            if (token) {
                const newSocket = new WebSocket(`wss://www.kookutalk.com/ws/chat/?token=${token}`);

                newSocket.onopen = () => {
                    console.log('Connected to chat WebSocket');
                    setSocketReady(true);
                };

                newSocket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    console.log('WebSocket message received in ChatContext:', data);
                    handleIncomingMessage(data);
                };

                newSocket.onclose = () => {
                    console.log('Disconnected from chat WebSocket');
                    setChatStatus('ended');
                    setSocketReady(false);
                };

                newSocket.onerror = (error) => {
                    console.error('WebSocket Error:', error);
                    setChatStatus('error');
                    setSocketReady(false);
                };

                setChatSocket(newSocket);
                return () => {
                    if (newSocket) {
                        console.log('Closing chat WebSocket');
                        newSocket.removeEventListener('message', handleIncomingMessage);
                        newSocket.close();
                    }
                };
            }
        }
    }, [currentUser]);

    const handleIncomingMessage = useCallback((data) => {
        console.log('Received WebSocket message:', data);
        switch (data.type) {
            case 'chat_requested':
                setChatStatus('requesting');
                setCurrentChat({ id: data.chat_id });
                break;
            case 'chat_accepted':
                console.log(`Chat accepted: ${data.chat_id}`);
                setChatStatus('active');
                setCurrentChat(prevChat => ({
                    ...prevChat,
                    id: data.chat_id,
                    modelId: data.model_id,
                    modelName: data.model_name,
                }));
                break;
            case 'chat_message':
                console.log(`Received message for chat ${data.message.chat_id}:`, data.message);
                setMessages(prevMessages => [...prevMessages, {
                    ...data.message,
                    isMine: data.message.sender_id === currentUser.id.toString()
                }]);
                break;
            case 'chat_ended':
                setChatStatus('ended');
                setCurrentChat(null);
                setMessages([]);
                break;
            case 'typing_status_update':
                if (data.user_id !== currentUser.id.toString()) {
                    setPartnerIsTyping(data.is_typing);
                }
                break;
            case 'error':
                console.error('Error message received:', data.message);
                setChatStatus('error');
                break;
            case 'chat_rejected':
                console.log(`Chat ${data.chat_id} rejected`);
                setChatStatus('rejected');
                setCurrentChat(null);
                break;
            case 'chat_status_update':
                console.log(`Chat status updated: ${data.status} for chat ${data.chat_id}`);
                setChatStatus(data.status);
                break;
            default:
                console.log('Unknown message type:', data.type);
        }
    }, [currentUser]);

    const requestChat = useCallback((modelId) => {
        return new Promise((resolve, reject) => {
            if (chatSocket && socketReady) {
                chatSocket.send(JSON.stringify({
                    type: 'request_chat',
                    model_id: modelId
                }));

                const onResponse = (event) => {
                    const data = JSON.parse(event.data);
                    if (data.type === 'error') {
                        reject(new Error(data.message));
                    } else if (data.type === 'chat_requested' && data.chat_id) {
                        resolve(data);
                    }
                };

                chatSocket.addEventListener('message', onResponse, { once: true });
            } else {
                reject(new Error('WebSocket not ready'));
            }
        });
    }, [chatSocket, socketReady]);

    const sendMessage = useCallback((content) => {
        if (chatSocket && socketReady && currentChat && chatStatus === 'active' && currentUser) {
            const messageToSend = {
                type: 'chat_message',
                message: {
                    content: content,
                    chat_id: currentChat.id,
                },
            };

            try {
                chatSocket.send(JSON.stringify(messageToSend));
            } catch (error) {
                console.error('Failed to send message:', error);
            }
        } else {
            console.warn('Cannot send message: WebSocket not ready, no active chat, or chat not active.');
        }
    }, [chatSocket, socketReady, currentChat, chatStatus, currentUser]);

    const endChat = useCallback(() => {
        if (chatSocket && socketReady && currentChat) {
            chatSocket.send(JSON.stringify({
                type: 'end_chat',
                chat_id: currentChat.id,
            }));
        } else {
            console.warn('Cannot end chat: No active chat or WebSocket is not connected.');
        }
    }, [chatSocket, socketReady, currentChat]);

    const sendTypingStatus = useCallback((isTyping) => {
        setIsTyping(isTyping);
        if (chatSocket && socketReady && currentChat && chatStatus === 'active') {
            chatSocket.send(JSON.stringify({
                type: 'typing_status',
                is_typing: isTyping,
            }));
        }
    }, [chatSocket, socketReady, currentChat, chatStatus]);

    const acceptChat = useCallback((chatId) => {
        if (chatSocket && socketReady) {
            chatSocket.send(JSON.stringify({
                type: 'accept_chat',
                chat_id: chatId,
            }));
        }
    }, [chatSocket, socketReady]);

    const rejectChat = useCallback((chatId) => {
        if (chatSocket && socketReady) {
            chatSocket.send(JSON.stringify({
                type: 'reject_chat',
                chat_id: chatId,
            }));
        }
    }, [chatSocket, socketReady]);

    const value = {
        currentChat,
        messages,
        chatStatus,
        setChatStatus,
        setCurrentChat,
        requestChat,
        sendMessage,
        endChat,
        isTyping,
        partnerIsTyping,
        sendTypingStatus,
        socketReady,
        acceptChat,
        rejectChat,
        currentUser
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = () => {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};

export default ChatContext;
