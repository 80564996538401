import React from 'react';
import { Typography, Box, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PaymentFailed = () => {
  const navigate = useNavigate();

  return (
    <Card variant="outlined" sx={{ mt: 4, borderRadius: '16px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
      <CardContent sx={{ padding: '32px', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom fontWeight="bold" color="error">
          Payment Failed
        </Typography>
        <Typography variant="body1" gutterBottom>
          We're sorry, but your payment could not be processed at this time.
        </Typography>
        <Box mt={4}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/')} 
            sx={{ mr: 2 }}
          >
            Return Home
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={() => navigate('/MyWallet')}
          >
            Try Again
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PaymentFailed;