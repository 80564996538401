import React, { useContext } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, styled, Typography, Avatar, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import RecommendIcon from '@mui/icons-material/Recommend';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import CoinIcon from '../assets/images/Coins.png';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 280,
    background: '#111',
    color: '#fff',
    fontFamily: "'Inter', sans-serif",
  },
}));

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  padding: theme.spacing(1.5, 2),
  borderRadius: 12,
  backgroundColor: active ? 'rgba(247, 184, 21, 0.1)' : 'transparent',
  margin: theme.spacing(0.5, 1),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: active ? 'rgba(247, 184, 21, 0.2)' : 'rgba(255, 255, 255, 0.05)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme, active }) => ({
  color: active ? '#F7B815' : '#888',
  minWidth: 40,
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#1A1A1A',
  borderRadius: 16,
  margin: theme.spacing(2, 1),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  border: '3px solid #F7B815',
  marginBottom: theme.spacing(2),
}));

const WalletBalance = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 2),
  backgroundColor: '#222',
  borderRadius: 12,
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
}));

const SidebarMenu = ({ isOpen, onClose }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = React.useState(location.pathname);

  React.useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    { text: 'Home', icon: HomeIcon, path: '/' },
    { text: 'Recommended', icon: RecommendIcon, path: '/recommended' },
    { text: 'My Favourite', icon: FavoriteIcon, path: '/favorites' },
    { text: 'My Wallet', icon: AccountBalanceWalletIcon, path: '/MyWallet' },
    { text: 'Conversation', icon: ChatIcon, path: '/chat' },
  ];

  const handleItemClick = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <StyledDrawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={onClose} sx={{ color: '#F7B815' }}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      {user && (
        <ProfileSection>
          <StyledAvatar
            src={user.profile_picture ? `${process.env.REACT_APP_API_URL}${user.profile_picture}` : ''}
          />
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#F7B815' }}>
            {`${user.first_name} ${user.last_name}`}
          </Typography>
          <Typography variant="body2" sx={{ color: '#888', mb: 2 }}>
            {user.email}
          </Typography>
          <WalletBalance>
            <img src={CoinIcon} alt="Coin" style={{ width: 20, height: 20, marginRight: 8 }} />
            <Typography variant="body1" sx={{ fontWeight: 500, color: '#F7B815' }}>
              {user.wallet_balance} Coins
            </Typography>
          </WalletBalance>
        </ProfileSection>
      )}
      <List sx={{ mt: 2 }}>
        {menuItems.map((item) => {
          const Icon = item.icon;
          const active = activePath === item.path;
          return (
            <StyledListItem
              button
              key={item.text}
              onClick={() => handleItemClick(item.path)}
              active={active}
            >
              <StyledListItemIcon active={active}>
                <Icon />
              </StyledListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{ 
                  '& .MuiTypography-root': { 
                    fontWeight: 500,
                    color: active ? '#F7B815' : '#fff'
                  } 
                }} 
              />
            </StyledListItem>
          );
        })}
      </List>
    </StyledDrawer>
  );
};

export default SidebarMenu;