import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, Typography, Button, Grid, Card, CardContent, List, ListItem, 
  ListItemText, Divider, Avatar, Box, Paper, Tabs, Tab, Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getTransactions, getUserProfile, getDepositRequests, getPaymentSettings } from '../../services/api';
import CoinIcon from '../../assets/images/Coins.png';

const PREDEFINED_COINS = [40, 100, 200, 300, 400, 450, 500, 1000];

const Wallet = () => {
  const [transactions, setTransactions] = useState([]);
  const [depositRequests, setDepositRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [success,] = useState(null); // Remove this line if not used
  const [walletBalance, setWalletBalance] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [coinRate, setCoinRate] = useState(8.00); // Default value
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [transactionResponse, userProfileResponse, depositRequestsResponse, paymentSettingsResponse] = await Promise.all([
        getTransactions(),
        getUserProfile(),
        getDepositRequests(),
        getPaymentSettings()
      ]);

      // Check if user is a model
      if (userProfileResponse.data.user_type==='model') {
        navigate('/models/wallet');
        return; // Stop further execution of this component
      }

      setTransactions(transactionResponse.data);
      setWalletBalance(userProfileResponse.data.wallet_balance);

      // Sort deposit requests by the created_at field in descending order (latest first)
      const sortedDepositRequests = depositRequestsResponse.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setDepositRequests(sortedDepositRequests);

      // Set coin rate from API
      setCoinRate(paymentSettingsResponse.data.deposit_rate || 8.00); // Use default if API fails
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleAddCoins = () => {
    const amountInCoins = parseInt(amount, 10);
    if (isNaN(amountInCoins) || amountInCoins <= 0) {
      setError('Invalid amount');
      return;
    }
    navigate('/Checkout', { state: { amount: amountInCoins } });
  };

  const handlePredefinedClick = (coins) => {
    setAmount(coins);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTransactions = () => (
    <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
      {transactions.length > 0 ? (
        transactions.map((transaction) => (
          <React.Fragment key={transaction.id}>
            <ListItem>
              <Avatar src={CoinIcon} sx={{ width: 24, height: 24, mr: 2 }} />
              <ListItemText
                primary={`${transaction.amount} Coins`}
                secondary={`Description: ${transaction.description} | ${new Date(transaction.timestamp).toLocaleString()}`}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No transactions found" />
        </ListItem>
      )}
    </List>
  );

  const renderDepositRequests = () => (
    <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
      {depositRequests.length > 0 ? (
        depositRequests.map((request) => (
          <React.Fragment key={request.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography>
                    Amount: ₹{request.amount} ({(request.amount / coinRate)} Coins)
                  </Typography>
                }
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      Status: {request.status}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      Date: {new Date(request.created_at).toLocaleString()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No deposit requests found" />
        </ListItem>
      )}
    </List>
  );

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mb: 2, borderRadius: '12px', marginTop:'20px' }}>
        <Typography variant="h5" gutterBottom>
          Wallet Balance
        </Typography>
        <Box display="flex" alignItems="center">
          {loading ? (
            <>
              <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
              <Skeleton variant="text" width={100} height={40} />
            </>
          ) : (
            <>
              <Avatar src={CoinIcon} sx={{ width: 40, height: 40, mr: 2 }} />
              <Typography variant="h4" color="primary" gutterBottom>
                {walletBalance} Coins
              </Typography>
            </>
          )}
        </Box>
      </Paper>

      {loading ? (
        <Skeleton variant="text" width={300} height={24} />
      ) : (
        <Typography variant="body1" paragraph>
          1 Coin rate is ₹{coinRate}. For example:
          <br />
          100 coins = ₹{(100 * coinRate).toFixed(2)}
        </Typography>
      )}

      <Card variant="outlined" sx={{ mb: 2, borderRadius: '12px', overflow: 'hidden' }}>
        <CardContent>
          <Typography variant="h6">Add Coins</Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {PREDEFINED_COINS.map((coins) => (
              <Grid item xs={6} sm={4} md={3} key={coins}>
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height={60} />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handlePredefinedClick(coins)}
                    sx={{
                      width: '100%',
                      height: '60px',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      textTransform: 'none'
                    }}
                  >
                    <Avatar src={CoinIcon} sx={{ width: 24, height: 24, mr: 1 }} />
                    {coins} Coins
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={48} />
              ) : (
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter coins"
                  style={{ 
                    width: '100%', 
                    padding: '12px', 
                    borderRadius: '4px', 
                    border: 'none', 
                    borderBottom: '2px solid #ddd', 
                    boxShadow: 'none', 
                    outline: 'none',
                    marginBottom: '16px',
                    fontSize: '16px',
                    boxSizing: 'border-box'
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={48} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddCoins}
                  sx={{ width: '100%', borderRadius: '8px' }}
                >
                  Add Coins
                </Button>
              )}
            </Grid>
          </Grid>
          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
          {success && <Typography color="success" sx={{ mt: 2 }}>{success}</Typography>}
        </CardContent>
      </Card>

      {loading ? (
        <Box>
          <Tabs value={0} sx={{ mb: 2 }}>
            <Tab label={<Skeleton variant="text" width={120} height={32} />} />
            <Tab label={<Skeleton variant="text" width={120} height={32} />} />
          </Tabs>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
      ) : (
        <>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Transaction History" />
            <Tab label="Deposit Requests" />
          </Tabs>

          {activeTab === 0 && renderTransactions()}
          {activeTab === 1 && renderDepositRequests()}
        </>
      )}
    </Container>
  );
};

export default Wallet;