import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { ChatNotificationProvider } from './contexts/NotificationsContext';
import { UserStatusProvider } from './contexts/UserStatusContext';
import { ChatProvider } from './contexts/ChatContext';
import AgePopup from './Components/AgePopup';
import { VoiceCallProvider } from './contexts/VoiceCallContext';
import CallScreen from './Components/CallScreen';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
            <UserStatusProvider>
              <ChatProvider>
              <ChatNotificationProvider>
              <VoiceCallProvider>
                <App />
                <CallScreen />
                </VoiceCallProvider>
                </ChatNotificationProvider>
              </ChatProvider>
            </UserStatusProvider>
            <AgePopup />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
