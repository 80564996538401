import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Link,
  AppBar,
  Toolbar,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIcon from '@mui/icons-material/Phone';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useParams, useNavigate } from 'react-router-dom';
import useModelData from '../../hooks/useModelData';
import { AuthContext } from '../../contexts/AuthContext';
import { useChat } from '../../contexts/ChatContext';
import { useUserStatus } from '../../contexts/UserStatusContext';
import { useVoiceCall } from '../../contexts/VoiceCallContext';
import placeholderAvatar from '../../assets/images/placeholder_avatar.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Rating from '@mui/material/Rating';

const MainContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  border: '1px solid #00000033',
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const ProfileImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ProfileImage = styled(Avatar)(({ theme }) => ({
  width: 150,
  height: 150,
  marginBottom: theme.spacing(2),
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    width: 250,
    height: 250,
  },
}));

const FollowButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2),
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: '#616161',
}));

const InfoItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(1),
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

const MediaItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 200,
  [theme.breakpoints.up('md')]: {
    height: 400,
  },
}));

const MediaContent = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: theme.spacing(1),
  border: '1px solid #00000033',
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.3)',
  borderRadius: theme.spacing(1),
}));

const PlayButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const MobileFooter = styled(AppBar)(({ theme }) => ({
  top: 'auto',
  bottom: 0,
  background: theme.palette.background.paper,
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
}));

const FooterButton = styled(Button)(({ theme }) => ({
  flex: 1,
  marginLeft: '10px',
  borderRadius: theme.shape.borderRadius,
  height: '50px',
}));

const TruncatedText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktop) {
    return <Typography variant="body2">{text}</Typography>;
  }

  if (text.length <= maxLength) {
    return <Typography variant="body2">{text}</Typography>;
  }

  return (
    <>
      <Typography variant="body2">
        {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      </Typography>
      <Link
        component="button"
        variant="body2"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ mt: 1, display: 'block' }}
      >
        {isExpanded ? 'Read less' : 'Read more'}
      </Link>
    </>
  );
};

const MediaGallery = ({ media }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [thumbnails, setThumbnails] = useState({});

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleVideoClick = (videoSrc) => {
    setSelectedVideo(videoSrc);
  };

  const generateThumbnail = (videoSrc) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.src = videoSrc;
      video.crossOrigin = 'anonymous';
      video.muted = true;
      video.currentTime = 1;
      video.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };
    });
  };

  useEffect(() => {
    const loadThumbnails = async () => {
      const thumbnailPromises = media
        .filter((item) => item.media_type === 'video')
        .map(async (item) => {
          const thumbnail = await generateThumbnail(item.file);
          return { id: item.id, thumbnail };
        });

      const thumbnailResults = await Promise.all(thumbnailPromises);
      const newThumbnails = thumbnailResults.reduce((acc, { id, thumbnail }) => {
        acc[id] = thumbnail;
        return acc;
      }, {});

      setThumbnails(newThumbnails);
    };

    loadThumbnails();
  }, [media]);

  return (
    <>
      <Slider {...settings}>
        {media.map((item) => (
          <Box key={item.id} padding={1}>
            <MediaItem>
              {item.media_type === 'photo' ? (
                <MediaContent src={item.file} alt={item.caption || 'Gallery image'} />
              ) : (
                <>
                  <MediaContent
                    src={thumbnails[item.id] || `${item.file}#t=0.1`}
                    alt={item.caption || 'Video thumbnail'}
                  />
                  <VideoOverlay>
                    <PlayButton
                      startIcon={<PlayArrowIcon />}
                      onClick={() => handleVideoClick(item.file)}
                    >
                      Play
                    </PlayButton>
                  </VideoOverlay>
                </>
              )}
            </MediaItem>
          </Box>
        ))}
      </Slider>

      <Dialog open={!!selectedVideo} onClose={() => setSelectedVideo(null)} maxWidth="md" fullWidth>
        <DialogContent>
          <video src={selectedVideo} controls style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const ModelProfileDetails = () => {
  const { modelId } = useParams();
  const { modelData: model, loading, error } = useModelData(modelId);
  const { user } = useContext(AuthContext);
  const { requestChat, currentChat, chatStatus, insufficientBalance } = useChat();
  const { onlineUsers } = useUserStatus();
  const navigate = useNavigate();
  const [requestingChat, setRequestingChat] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { callState, initiateCall, endCall } = useVoiceCall();
  const [requestingCall, setRequestingCall] = useState(false);

  useEffect(() => {
    if (insufficientBalance) {
      setAlertMessage("Insufficient balance to start chat.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      setRequestingChat(false);
    }
  }, [insufficientBalance]);

  useEffect(() => {
    if (currentChat && chatStatus === 'active') {
      navigate(`/chat/${currentChat.id}`, { state: { modelId } });
    } else if (chatStatus === 'idle') {
      setRequestingChat(false);
    }
  }, [currentChat, chatStatus, navigate, modelId]);

  useEffect(() => {
    if (callState.status === 'ongoing') {
      setRequestingCall(false);
      setAlertMessage("Call connected successfully.");
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } else if (callState.status === 'idle') {
      setRequestingCall(false);
      if (callState.error) {
        setAlertMessage("Failed to connect the call. Please try again.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }
    }
  }, [callState]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error loading model details</Typography>;
  if (!model) return <Typography>No model found</Typography>;

  const {
    id,
    first_name,
    last_name,
    is_online,
    profile_picture,
    bio,
    profile,
    gallery,
    ratings,
    average_rating,
    total_ratings,
  } = model;

  const {
    chat_price,
    audio_price,
    languages,
    fantasy,
    mood,
    relationship_status,
  } = profile;

  const handleStartChat = async () => {
    setRequestingChat(true);
    try {
      const response = await requestChat(id);
      if (response) {
        setAlertMessage(`Chat request sent to ${first_name} ${last_name}.`);
        setAlertSeverity("success");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setAlertMessage(error.message || "An error occurred while requesting the chat.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setRequestingChat(false);
    }
  };

  const handleStartCall = () => {
    setRequestingCall(true);
    initiateCall(id, false);
  };

  const isRegularUser = user && user.user_type === 'regular';
  const isModelOnline = is_online;


  return (
    <Box maxWidth={1200} margin="auto" padding={2} pb={isMobile ? 8 : 2}>
      <MainContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ProfileImageContainer>
              <ProfileImage
                src={profile_picture || placeholderAvatar}
                alt={`${first_name} ${last_name}`}
              />
              <FollowButton variant="contained" color="primary" size={isMobile ? "small" : "medium"}>Follow</FollowButton>
            </ProfileImageContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            <ProfileDetails>
              <Typography variant="h5" fontWeight="bold">{first_name} {last_name}</Typography>
              <Typography variant="body2" color={isModelOnline ? 'success.main' : 'textSecondary'} gutterBottom>
                {isModelOnline ? 'Online' : 'Offline'}
              </Typography>
              <InfoItem>
                <Typography variant="body2"><strong>Fantasy:</strong> {fantasy.join(', ')}</Typography>
              </InfoItem>
              <InfoItem>
                <Typography variant="body2"><strong>Mood:</strong> {mood.join(', ')}</Typography>
              </InfoItem>
              <InfoItem>
                <Typography variant="body2"><strong>Languages:</strong> {languages.join(', ')}</Typography>
              </InfoItem>
              <InfoItem>
                <Typography variant="body2"><strong>Relationship Status:</strong> {relationship_status}</Typography>
              </InfoItem>
              {!isMobile && (
                <Box mt={1}>
                  <ActionButton
                    variant="contained"
                    color="primary"
                    startIcon={<ChatIcon />}
                    onClick={handleStartChat}
                    disabled={chatStatus !== 'idle' || !isRegularUser}
                    size="medium"
                  >
                    Start Chat ({chat_price} Coins/Min)
                  </ActionButton>
                  <ActionButton
                    variant="contained"
                    color="secondary"
                    startIcon={<PhoneIcon />}
                    onClick={handleStartCall}
                    disabled={!isRegularUser || callState.status !== 'idle'}
                    size="medium"
                  >
                    Start Call ({audio_price} Coins/Min)
                  </ActionButton>
                </Box>
              )}
            </ProfileDetails>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography variant="h6" fontWeight="bold" gutterBottom style={{ fontSize: isMobile ? '18px' : '20px', textAlign: 'center', color: '#454545' }}>
            About Me
          </Typography>
          <TruncatedText text={bio || 'No bio available.'} maxLength={150} />
        </Box>

        <Box mt={2}>
          <MediaGallery media={gallery} />
        </Box>
      </MainContainer>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, borderRadius: 2, border: '1px solid #00000033', boxShadow: 'none' }}>
            <Typography variant="h6" gutterBottom>Ratings</Typography>
            {[5, 4, 3, 2, 1].map((star) => (
              <Box key={star} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Rating value={star} readOnly size={isMobile ? "small" : "medium"} />
                <Box width="60%" bgcolor="grey.200" height={8} borderRadius={4}>
                  <Box width={`${(ratings.filter(r => r.rating === star).length / ratings.length) * 100}%`} bgcolor="primary.main" height={8} borderRadius={4} />
                </Box>
                <Typography variant="body2">{ratings.filter(r => r.rating === star).length}</Typography>
              </Box>
            ))}
            <Typography variant="body2" mt={1}>Average Rating: {average_rating.toFixed(1)} ({total_ratings} total ratings)</Typography>
          </Paper>
        </Grid>
      </Grid>
      {isMobile && (
        <MobileFooter position="fixed" color="default">
          <Toolbar>
            <FooterButton
              variant="contained"
              color="primary"
              startIcon={<ChatIcon />}
              onClick={handleStartChat}
              disabled={chatStatus !== 'idle' || !isRegularUser}
            >
              Start Chat ({chat_price})
            </FooterButton>
            <FooterButton
              variant="contained"
              color="secondary"
              startIcon={<PhoneIcon />}
              onClick={handleStartCall}
              disabled={!isRegularUser || callState.status !== 'idle'}
            >
              Start Call ({audio_price})
            </FooterButton>
          </Toolbar>
        </MobileFooter>
      )}

      <Dialog
        open={requestingChat || requestingCall}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setRequestingChat(false);
            setRequestingCall(false);
            if (callState.status === 'initiating' || callState.status === 'ringing') {
              endCall();
            }
          }
        }}
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
            <CircularProgress size={24} />
            <Typography variant="body1" marginTop={2}>
              {requestingChat ? `Requesting chat with ${first_name} ${last_name}...` : 
               callState.status === 'initiating' ? `Initiating call with ${first_name} ${last_name}...` :
               callState.status === 'ringing' ? `Calling ${first_name} ${last_name}...` :
               `Connecting call with ${first_name} ${last_name}...`}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ModelProfileDetails;