// src/hooks/useModelData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

// Define the base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_URL;

const useModelData = (modelId) => {
  const [modelData, setModelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        setLoading(true);
        // Make an API call to fetch the model data
        const response = await axios.get(`${API_BASE_URL}/api/model/profile/details/${modelId}/`);
        setModelData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (modelId) {
      fetchModelData();
    }
  }, [modelId]);

  return { modelData, loading, error };
};

export default useModelData;
