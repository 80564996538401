import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Login from './Pages/Auth/Login';
import Signup from './Pages/Auth/Signup';
import UserProfile from './Pages/User/UserProfile';
import Header from './Pages/common/Header';
import Footer from './Pages/common/Footer';
import PageHeader from './Pages/common/PageHeader';
import ModelRegister from './Pages/Auth/ModelRegister';
import Wallet from './Pages/Wallet/Wallet';
import ModelWallet from './Pages/Wallet/ModelWallet';
import PrivateRoute from './Components/PrivateRoute';
import Checkout from './Pages/Wallet/Checkout';
import ModelProfileDetails from './Pages/Models/ModelProfileDetails';
import { useAuth } from './contexts/AuthContext';
import ChatRoom from './Pages/Chat/ChatRoom';
import { useVoiceCall } from './contexts/VoiceCallContext';

const App = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { callStatus } = useVoiceCall();

  const shouldShowPageHeader = () => {
    return location.pathname !== '/' && !location.pathname.startsWith('/chat/');
  };

  return (
    <>
      {location.pathname === '/' ? (
        <Header />
      ) : shouldShowPageHeader() ? (
        <PageHeader title={location.pathname.slice(1).replace(/\//g, ' ')} />
      ) : null}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/register/model" element={<ModelRegister />} />
        <Route path="/user/profile" element={<PrivateRoute component={UserProfile} />} />
        <Route path="/MyWallet" element={<PrivateRoute component={Wallet} />} />
        <Route path="/models/wallet" element={<PrivateRoute component={ModelWallet} />} />
        <Route path="/checkout" element={<PrivateRoute component={Checkout} />} />
        <Route path="/model/profile/details/:modelId" element={<ModelProfileDetails />} />
        <Route path="/chat/:chatId" element={<PrivateRoute component={ChatRoom} />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
