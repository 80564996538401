import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

const UserStatusContext = createContext();

export const UserStatusProvider = ({ children }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [userStatusSocket, setUserStatusSocket] = useState(null);

  const updateOnlineUsers = useCallback((users) => {
    setOnlineUsers(users);
  }, []);

  const updateUserStatus = useCallback((userId, isOnline) => {
    setOnlineUsers(prevUsers => {
      if (isOnline) {
        if (!prevUsers.some(user => user.id === userId)) {
          return [...prevUsers, { id: userId }];
        }
      } else {
        return prevUsers.filter(user => user.id !== userId);
      }
      return prevUsers;
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const wsUrl = token
      ? `wss://www.kookutalk.com/ws/user_status/?token=${token}`
      : 'wss://www.kookutalk.com/ws/user_status/';

    const newUserStatusSocket = new WebSocket(wsUrl);

    newUserStatusSocket.onopen = () => {
      console.log('Connected to user status WebSocket');
    };

    newUserStatusSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'user_list') {
        updateOnlineUsers(data.online_users);
      } else if (data.type === 'status_change') {
        updateUserStatus(data.user_id, data.is_online);
      }
    };

    newUserStatusSocket.onclose = () => {
      console.log('Disconnected from user status WebSocket');
    };

    setUserStatusSocket(newUserStatusSocket);

    return () => {
      newUserStatusSocket.close();
    };
  }, [updateOnlineUsers, updateUserStatus]);

  const value = {
    onlineUsers,
    userStatusSocket,
    isUserOnline: useCallback((userId) => onlineUsers.some(user => user.id === userId), [onlineUsers]),
  };

  return (
    <UserStatusContext.Provider value={value}>
      {children}
    </UserStatusContext.Provider>
  );
};

export const useUserStatus = () => {
  const context = useContext(UserStatusContext);
  if (!context) {
    throw new Error('useUserStatus must be used within a UserStatusProvider');
  }
  return context;
};