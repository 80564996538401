import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';

const AgePopup = () => {
  const [open, setOpen] = useState(false);
  const theme = useMuiTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const hasAgreed = localStorage.getItem('ageVerified');
    if (!hasAgreed) {
      setOpen(true);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem('ageVerified', 'true');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      disableEscapeKeyDown
      disableBackdropClick
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          margin: 0,
          borderRadius: 0,
          padding: 2,
          background: 'linear-gradient(175deg, #A2252D 0%, black 100%)',
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold" align="center" color="white">
          Age Verification
        </Typography>
      </DialogTitle>
      <Divider sx={{ borderColor: 'white' }} />
      <DialogContent>
        <Box mt={2} mb={3}>
          <Typography variant="h6" align="center" gutterBottom color="white">
            We are creating a better experience for 18+ LIVE entertainment.
            Join our open-minded community & start interacting now for FREE.
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            By entering and using this website, you confirm you’re over 18 years old and agree to be bound by the Terms of Use and Privacy Policy 18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement.
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            If you provide sensitive personal data, by entering the website, you give your explicit consent to process this data to tailor the website to your preferences. If you’re looking for a way to restrict access for a minor, see our Parental Control Guide.
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            <strong>Exit Here</strong>
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            THIS WEBSITE CONTAINS MATERIAL THAT IS SEXUALLY EXPLICIT. You must be at least eighteen (18) years of age to use this Website, unless the age of majority in your jurisdiction is greater than eighteen (18) years of age, in which case you must be at least the age of majority in your jurisdiction. Use of this Website is not permitted where prohibited by law.
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            This Website also requires the use of cookies. More information about our cookies can be found at our Privacy Policy. BY ENTERING THIS WEBSITE AND USING THIS WEBSITE YOU AGREE TO THE USE OF COOKIES AND ACKNOWLEDGE THE PRIVACY POLICY.
          </Typography>
          <Typography variant="body2" align="center" color="white" gutterBottom>
            All models were 18 and over at the time of the creation of such depictions.
          </Typography>
        </Box>
      </DialogContent>
      <Divider sx={{ borderColor: 'white' }} />
      <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Button
          onClick={handleAgree}
          variant="contained"
          sx={{
            minWidth: 120,
            padding: '8px 16px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgePopup;
