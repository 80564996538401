import axios from 'axios';

const API_URL = 'https://www.kookutalk.com/api/';

const api = axios.create({
  baseURL: API_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onAccessTokenFetched = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && !config.url.includes('login') && !config.url.includes('register')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          addRefreshSubscriber((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const { data } = await refreshToken(localStorage.getItem('refreshToken'));
        localStorage.setItem('token', data.access);
        onAccessTokenFetched(data.access);
        return api(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

// Authentication
export const registerUser = (userData) => api.post('register/user/', userData);
export const registerModel = (modelData) => api.post('register/model/', modelData);
export const login = (email, password) => api.post('login/', { email, password });
export const refreshToken = (refresh) => api.post('token/refresh/', { refresh });

// User and Model Profiles
export const getUserProfile = () => api.get('user/profile/');
export const getUserDetails = (user_id) => api.get(`user/${user_id}/`);
export const updateUserProfile = (userData) => api.put('user/profile/update/', userData);
export const getModelProfile = (modelId) => api.get(`model/profile/${modelId}/`);
export const updateModelProfile = (modelData) => api.put('model/profile/update/', modelData);
export const getModelProfileDetails = (modelId) => api.get(`model/profile/details/${modelId}/`); // Added this line


// Online Status
export const setOnlineStatus = (status) => api.post('status/online/', { status });
export const getModels = () => api.get('models/');
export const getModelStatus = (modelId) => api.get(`model/status/${modelId}/`);

// Deposit Requests
export const createDepositRequest = (depositData) => api.post('deposit-request/create/', depositData);
export const getDepositRequests = () => api.get('deposit-requests/');
export const getDepositRequestDetail = (depositRequestId) => api.get(`deposit-request/${depositRequestId}/`);

// Chat
export const requestChat = (modelId) => api.post(`chat/request/${modelId}/`);
export const acceptChat = (chatId) => api.post(`chat/accept/${chatId}/`);
export const rejectChat = (chatId) => api.post(`chat/reject/${chatId}/`);
export const startChat = (chatId) => api.post(`chat/start/${chatId}/`);
export const endChat = (chatId) => api.post(`chat/end/${chatId}/`);
export const getChatHistory = (chatId) => api.get(`chat/history/${chatId}/`);
export const getChatMessages = (chatId) => api.get(`chat/${chatId}/messages/`);
export const sendMessage = (chatId, message) => api.post(`chat/${chatId}/message/`, { message });
export const markMessagesAsRead = (chatId) => api.post(`chat/${chatId}/messages/mark-read/`);

// Voice Call
export const startVoiceCall = (modelId) => api.post(`voice-call/start/${modelId}/`);
export const endVoiceCall = (callId) => api.post(`voice-call/end/${callId}/`);

// Ratings
export const rateModel = (modelId, chatId, score, review) =>
  api.post(`model/${modelId}/rate/`, { chat: chatId, score, review });
// Get Model Ratings
export const getModelRatings = (modelId) =>
  api.get(`model/${modelId}/ratings/`);


// Notifications
export const getNotifications = () => api.get('notifications/');
export const markNotificationRead = (notificationId) => api.post(`notifications/read/${notificationId}/`);

// Transactions and Payments
export const getTransactions = () => api.get('transactions/');
export const getPaymentSettings = () => api.get('payment/settings/');
export const updatePaymentSettings = (settingsData) => api.put('payment/settings/update/', settingsData);

// Payout Requests
export const createPayoutRequest = (payoutData) => api.post('payout-request/create/', payoutData);
export const getPayoutRequests = () => api.get('payout-requests/');
export const getPayoutRequestDetail = (payoutRequestId) => api.get(`payout-request/${payoutRequestId}/`);
export const updatePayoutRequestStatus = (payoutRequestId, statusData) => api.post(`payout-request/${payoutRequestId}/update/`, statusData);

// Model Statistics
export const getModelStatistics = () => api.get('model/statistics/');

// Gallery
export const uploadGalleryPhoto = (formData) => api.post('gallery/upload/', formData, {
  headers: { 'Content-Type': 'multipart/form-data' }
});
export const deleteGalleryPhoto = (photoId) => api.delete(`gallery/photo/${photoId}/`);
export const getModelGallery = (modelId) => api.get(`model/${modelId}/gallery/`);

// Blocking
export const blockUser = (userId) => api.post(`user/${userId}/block/`);
export const unblockUser = (userId) => api.post(`user/${userId}/unblock/`);
export const getBlockedUsers = () => api.get('blocked-users/');

// Earnings
export const getUserEarnings = () => api.get('earnings/');

// Search
export const searchModels = (query) => api.get(`search/models/?q=${query}`);

export default api;
