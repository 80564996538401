import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material';
import QRCode from 'react-qr-code';
import PhonePeIcon from '../../assets/images/phonpe.png';
import GooglePayIcon from '../../assets/images/googlepe.png';
import PaytmIcon from '../../assets/images/Paytm_logo.png';
import CoinIcon from '../../assets/images/Coins.png';
import UpiIcon from '../../assets/images/upi.png';
import { useLocation } from 'react-router-dom';
import {
  createDepositRequest,
  getPaymentSettings,
} from '../../services/api';
import ThankYou from '../../Components/ThankYou';
import PaymentFailed from '../../Components/PaymentFailed';

const DepositForm = ({ open, onClose, amount, onConfirm }) => {
  const [formData, setFormData] = useState({
    account_holder_name: '',
    transaction_number: '',
    upi_id: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    onClose('failed');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!formData.account_holder_name || !formData.transaction_number) {
      setError('A/C Holder Name and Transaction Number are required.');
      return;
    }

    try {
      const depositData = {
        ...formData,
        amount,
      };
      await onConfirm(depositData);
    } catch (error) {
      setError('Failed to submit deposit request. Please try again.');
      console.error('Deposit request error:', error);
    }
  };

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <Card sx={{ maxWidth: 280, width: '100%', p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Confirm Deposit
        </Typography>
        <form onSubmit={handleSubmit}>
          <Typography variant="body1" gutterBottom>
            Amount to deposit: ₹{amount}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="A/C Holder Name"
            name="account_holder_name"
            value={formData.account_holder_name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Transaction Number"
            name="transaction_number"
            value={formData.transaction_number}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="UPI ID (Optional)"
            name="upi_id"
            value={formData.upi_id}
            onChange={handleChange}
          />
          {error && (
            <Box mt={2}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Confirm Deposit
            </Button>
          </Box>
        </form>
      </Card>
    </Box>
  );
};

const Checkout = () => {
  const location = useLocation();
  const { amount } = location.state || { amount: 0 };
  const [selectedMethod, setSelectedMethod] = useState('phonepe');
  const [isProcessing, setIsProcessing] = useState(false);
  const [timer, setTimer] = useState(240);
  const [showDepositForm, setShowDepositForm] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [depositRate, setDepositRate] = useState(8);
  const [paymentUPIId, setPaymentUPIId] = useState('');
  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      try {
        const { data } = await getPaymentSettings();
        setDepositRate(data.deposit_rate || 8);
        setPaymentUPIId(data.upi_id || 'starwebtech@ibl');
      } catch (error) {
        console.error('Error fetching payment settings:', error);
      }
    };

    fetchPaymentSettings();
  }, []);

  const paymentAmount = amount * depositRate;

  const generateUniversalUpiLink = () => {
    const payeeName = 'Star Webtech';
    const message = 'Deposit Amount';
    return `upi://pay?pa=${paymentUPIId}&pn=${payeeName}&am=${paymentAmount.toFixed(2)}&tn=${encodeURIComponent(message)}`;
  };

  const generatePaymentLink = () => {
    const payeeName = 'Star Webtech';
    const message = 'Deposit Amount';
    const orderId = 'Hplf07EM9kaXi'; // You might want to generate this dynamically

    switch (selectedMethod) {
      case 'phonepe':
        return `phonepe://pay?pa=${paymentUPIId}&pn=${payeeName}&am=${paymentAmount.toFixed(2)}&tr=${orderId}&mc=8931&orgid=000000&mode=01&cu=INR&tn=${encodeURIComponent(message)}`;
      case 'googlepay':
        return `gpay://upi/pay?pa=${paymentUPIId}&pn=${payeeName}&am=${paymentAmount.toFixed(2)}&tr=${orderId}&mc=8931&orgid=000000&mode=01&cu=INR&tn=${encodeURIComponent(message)}`;
      case 'paytm':
        return `paytmmp://pay?pa=${paymentUPIId}&pn=${payeeName}&am=${paymentAmount.toFixed(2)}&tr=${orderId}&mc=8931&orgid=000000&mode=01&cu=INR&tn=${encodeURIComponent(message)}`;
      default:
        return `upi://pay?pa=${paymentUPIId}&pn=${payeeName}&am=${paymentAmount.toFixed(2)}&tn=${encodeURIComponent(message)}`;
    }
  };

  const clearPaymentError = () => {
    setPaymentError(null);
  };

  const handlePayment = () => {
    clearPaymentError(); // Clear any existing error
    const paymentLink = generatePaymentLink();
    
    // Flag to track if payment was initiated
    let paymentInitiated = false;
  
    // Function to check if the payment was initiated
    const checkPaymentStatus = () => {
      if (document.hidden) {
        // If the document is hidden, assume the app has opened
        paymentInitiated = true;
        setIsProcessing(true);
      } else {
        setPaymentError("Payment app didn't open. Please make sure you have the app installed or try a different payment method.");
      }
    };
  
    // Set a timeout to check the payment status
    setTimeout(checkPaymentStatus, 3000);
  
    // Open the payment link
    window.location.href = paymentLink;
  
    // Add event listener for when the page becomes visible again
    const handleVisibilityChange = () => {
      if (!document.hidden && !paymentInitiated) {
        setPaymentError("Payment window was closed. Please try again or use a different payment method.");
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  };

  useEffect(() => {
    let intervalId;
    if (isProcessing) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setPaymentStatus('failed');
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isProcessing]);

  useEffect(() => {
    if (isProcessing && timer === 200) {
      setShowDepositForm(true);
    }
    if (timer === 0 && !showDepositForm) {
      setPaymentStatus('failed');
    }
  }, [isProcessing, timer, showDepositForm]);

  const handleDepositFormClose = (status) => {
    setShowDepositForm(false);
    if (status === 'failed' || timer === 0) {
      setPaymentStatus('failed');
    }
  };

  const handleDepositConfirmation = async (depositData) => {
    try {
      await createDepositRequest(depositData);
      setPaymentStatus('success');
    } catch (error) {
      console.error('Deposit request error:', error);
      setPaymentStatus('failed');
    }
  };

  const PaymentOption = ({ value, icon, label }) => (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" />}
      label={
        <Box display="flex" alignItems="center" sx={{ '& img': { mr: 1, width: 24, height: 24 } }}>
          <img src={icon} alt={label} />
          <Typography variant="body1">{label}</Typography>
        </Box>
      }
      sx={{
        border: '1px solid',
        borderColor: selectedMethod === value ? 'primary.main' : 'grey.300',
        borderRadius: 2,
        p: 1,
        mb: 1,
        width: '100%',
        transition: 'all 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    />
  );

  if (paymentStatus === 'success') {
    return <ThankYou amount={paymentAmount} />;
  }

  if (paymentStatus === 'failed') {
    return <PaymentFailed />;
  }

  return (
    <Container maxWidth="sm">
      {isProcessing ? (
        <Card variant="outlined" sx={{ mt: 4, borderRadius: '16px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
          <CardContent sx={{ padding: '32px', textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
              Processing Your Payment
            </Typography>
            
            <Box mt={4} position="relative" display="inline-block">
              <CircularProgress 
                size={120} 
                thickness={2} 
                sx={{ color: (theme) => theme.palette.grey[200] }} 
              />
              <CircularProgress 
                size={120} 
                thickness={2} 
                variant="determinate" 
                value={(240 - timer) / 240 * 100} 
                sx={{ 
                  position: 'absolute', 
                  left: 0, 
                  color: (theme) => theme.palette.primary.main,
                  animation: 'pulse 1.5s ease-in-out infinite',
                  '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.7 },
                    '100%': { opacity: 1 },
                  },
                }} 
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h4" component="div" fontWeight="bold">
                  {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                </Typography>
              </Box>
            </Box>
            
            <Typography variant="body1" color="textSecondary" mt={4} fontWeight="medium">
              Please wait while we confirm your transaction.
            </Typography>
            
            <Box mt={3} display="flex" justifyContent="center" alignItems="center">
              <Box component="span" sx={{ display: 'inline-block', mr: 1 }}>
                <CircularProgress size={16} thickness={6} />
              </Box>
              <Typography variant="body2" color="text.secondary">
                Verifying payment details...
              </Typography>
            </Box>
            
            <Box mt={4} bgcolor="rgba(0, 0, 0, 0.03)" borderRadius="8px" p={2}>
              <Typography variant="body2" color="text.secondary">
                Tip: Don't close this window. We'll redirect you automatically once the payment is confirmed.
              </Typography>
            </Box>
          </CardContent>
        </Card>
       ) : (
        <Card elevation={3} sx={{ mt: 4, borderRadius: 4, overflow: 'hidden' }}>
          <Box bgcolor="primary.main" color="primary.contrastText" p={3}>
            <Typography variant="h5" fontWeight="bold">
              Complete Your Payment
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography variant="h4" fontWeight="bold">
                ₹{paymentAmount.toFixed(2)}
              </Typography>
              <Box display="flex" alignItems="center">
                <img src={CoinIcon} alt="Coin" style={{ width: 24, height: 24, marginRight: 8 }} />
                <Typography variant="h6" fontWeight="bold">
                  {amount} Coins
                </Typography>
              </Box>
            </Box>
          </Box>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Scan QR Code
                </Typography>
                <Box bgcolor="grey.100" p={2} borderRadius={2} display="flex" justifyContent="center">
                  <QRCode value={generateUniversalUpiLink()} size={150} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Choose Payment Method
                </Typography>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="payment-method"
                    name="payment-method"
                    value={selectedMethod}
                    onChange={(e) => {
                      setSelectedMethod(e.target.value);
                      clearPaymentError(); // Clear any existing error when changing payment method
                    }}
                  >
                    <PaymentOption value="phonepe" icon={PhonePeIcon} label="PhonePe" />
                    <PaymentOption value="googlepay" icon={GooglePayIcon} label="Google Pay" />
                    <PaymentOption value="paytm" icon={PaytmIcon} label="Paytm" />
                    <PaymentOption value="upi" icon={UpiIcon} label="Other UPI Apps" />
                  </RadioGroup>
                </FormControl>
                </Grid>
            </Grid>
            {paymentError && (
              <Typography color="error" variant="body2" sx={{ mt: 2, mb: 1 }}>
                {paymentError}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handlePayment}
              disabled={!!paymentError}
              sx={{ 
                mt: 3, 
                py: 1.5, 
                borderRadius: 2,
                fontSize: '1.1rem',
                fontWeight: 'bold',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              Pay Now
            </Button>
          </CardContent>
        </Card>
      )}
      <DepositForm 
        open={showDepositForm} 
        onClose={handleDepositFormClose}
        amount={paymentAmount}
        onConfirm={handleDepositConfirmation}
      />
    </Container>
  );
};

export default Checkout;