import React, { useState } from 'react';
import { 
  Stepper, Step, StepLabel, Button, Typography, TextField, 
  Container, Paper, Grid, FormControl, InputLabel, Select, 
  MenuItem, Chip, Box, Avatar
} from '@mui/material';
import { styled } from '@mui/system';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

const steps = ['Basic Information', 'Profile Details', 'Pricing'];

const ModelRegister = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    date_of_birth: null,
    location: '',
    profile_picture: null,
    chat_price: '',
    audio_price: '',
    languages: [],
    fantasy: [],
    mood: [],
    relationship_status: '',
  });
  const [emailError, setEmailError] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateEmail = (email) => {
    const domain = email.split('@')[1];
    const allowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com'];
    if (!allowedDomains.includes(domain)) {
      setEmailError('Please use a Gmail, Yahoo, or Outlook email address.');
    } else {
      setEmailError('');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      validateEmail(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date_of_birth: date });
  };

  const handleMultiSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, profile_picture: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailError) return;
    try {
      const formDataToSend = new FormData();

      // Prepare user data
      const userData = {
        email: formData.email,
        username: formData.username,
        password: formData.password,
        confirm_password: formData.confirm_password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        date_of_birth: formData.date_of_birth.toISOString().split('T')[0],
        location: formData.location,
      };

      // Prepare profile data
      const profileData = {
        chat_price: formData.chat_price,
        audio_price: formData.audio_price,
        languages: formData.languages,
        fantasy: formData.fantasy,
        mood: formData.mood,
        relationship_status: formData.relationship_status,
      };

      // Append user and profile data
      Object.keys(userData).forEach((key) => {
        formDataToSend.append(`user.${key}`, userData[key]);
      });

      Object.keys(profileData).forEach((key) => {
        if (Array.isArray(profileData[key])) {
          profileData[key].forEach((item) => {
            formDataToSend.append(`profile.${key}`, item);
          });
        } else {
          formDataToSend.append(`profile.${key}`, profileData[key]);
        }
      });

      // Append profile picture if it exists
      if (formData.profile_picture) {
        formDataToSend.append('user.profile_picture', formData.profile_picture);
      }

      const response = await axios.post('http://10.35.168.104/api/register/model/', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log(response.data);
      // Handle successful registration (e.g., redirect to login page)
    } catch (error) {
      console.error('Registration error:', error.response.data);
      // Handle registration error (e.g., display error messages)
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formData.password}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
            />
          </>
        );
      case 1:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="first_name"
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="lname"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  selected={formData.date_of_birth}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  customInput={<TextField fullWidth label="Date of Birth" />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="profile_picture"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="profile_picture">
                  <Button variant="contained" component="span">
                    Upload Profile Picture
                  </Button>
                </label>
              </Grid>
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="chat_price"
              label="Chat Price (per minute)"
              name="chat_price"
              type="number"
              value={formData.chat_price}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="audio_price"
              label="Audio Call Price (per minute)"
              name="audio_price"
              type="number"
              value={formData.audio_price}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="languages-label">Languages</InputLabel>
              <Select
                labelId="languages-label"
                id="languages"
                multiple
                value={formData.languages}
                onChange={handleMultiSelectChange}
                name="languages"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {['English', 'Spanish', 'French', 'German', 'Italian'].map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="fantasy-label">Fantasy</InputLabel>
              <Select
                labelId="fantasy-label"
                id="fantasy"
                multiple
                value={formData.fantasy}
                onChange={handleMultiSelectChange}
                name="fantasy"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {['Roleplay', 'BDSM', 'Fetish', 'Vanilla', 'Other'].map((fantasy) => (
                  <MenuItem key={fantasy} value={fantasy}>
                    {fantasy}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="mood-label">Mood</InputLabel>
              <Select
                labelId="mood-label"
                id="mood"
                multiple
                value={formData.mood}
                onChange={handleMultiSelectChange}
                name="mood"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {['Happy', 'Flirty', 'Naughty', 'Romantic', 'Dominant', 'Submissive'].map((mood) => (
                  <MenuItem key={mood} value={mood}>
                    {mood}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="relationship-status-label">Relationship Status</InputLabel>
              <Select
                labelId="relationship-status-label"
                id="relationship_status"
                value={formData.relationship_status}
                onChange={handleChange}
                name="relationship_status"
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="In a Relationship">In a Relationship</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="It's Complicated">It's Complicated</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <StyledPaper elevation={6}>
        <StyledAvatar />
        <Typography component="h1" variant="h5">
          Model Registration
        </Typography>
        <Stepper activeStep={activeStep} style={{ marginTop: '20px', marginBottom: '20px' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            >
              {activeStep === steps.length - 1 ? 'Register' : 'Next'}
            </Button>
          </Box>
        </form>
      </StyledPaper>
    </Container>
  );
};

export default ModelRegister;
