import React, { useState, useContext } from 'react';
import { Box, TextField, Button, Typography, Link, IconButton, InputAdornment, Stepper, Step, StepLabel, Avatar, Alert } from '@mui/material';
import { Visibility, VisibilityOff, Close as CloseIcon, CameraAlt as CameraAltIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../contexts/AuthContext';


const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgb(0,0,0, 0.4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
});

const PopupContainer = styled(Box)(({ theme }) => ({
  padding: '2rem',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '300px',
  position: 'absolute',
  textAlign: 'center',
  color: '#C8C4C4',
  right:'120px',
  background: 'linear-gradient(to bottom right, #3B2122, #241F21)',
  minHeight:'500px',
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    right:'0',
  },
}));

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: '#3B2122',
  backgroundColor: '#FFDF85',
  '&:hover': {
    backgroundColor: '#FFD452',
  },
});

const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
  '& .MuiInputBase-root': {
    backgroundColor: '#4A3939',
    borderRadius: '8px',
    color: '#C8C4C4',
  },
  '& .MuiInputLabel-root': {
    color: '#C8C4C4',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
});

const StyledButton = styled(Button)({
  marginTop: '1rem',
  padding: '0.75rem',
  borderRadius: '8px',
  backgroundColor: '#A2252D',
  color: '#C8C4C4',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#8B1E25',
  },
});

const AvatarInput = styled('input')({
  display: 'none',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  margin: 'auto',
  marginBottom: theme.spacing(2),
}));

const LoginPopup = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    profile_picture: null,
    date_of_birth: '',
    location: '',
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState('');
  const { loginUser, registerUser } = useContext(AuthContext);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profile_picture: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateStep = (step) => {
    switch (step) {
      case 0:
        if (!formData.first_name || !formData.last_name || !formData.date_of_birth || !formData.location) {
          setError('Please fill all required fields.');
          return false;
        }
        const birthDate = new Date(formData.date_of_birth);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 18) {
          setError('You must be at least 18 years old to register.');
          return false;
        }
        break;
      case 1:
        if (!formData.username || !formData.email || !formData.password) {
          setError('Please fill all required fields.');
          return false;
        }
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
          setError('Please enter a valid email address.');
          return false;
        }
        if (formData.password.length < 8) {
          setError('Password must be at least 8 characters long.');
          return false;
        }
        break;
      default:
        return true;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (isLogin) {
        if (!formData.email || !formData.password) {
          setError('Please fill all required fields.');
          return;
        }
        await loginUser(formData.email, formData.password);
      } else {
        if (!validateStep(activeStep)) {
          return;
        }
        const formDataToSend = new FormData();
        for (const key in formData) {
          if (key === 'profile_picture') {
            if (formData[key]) {
              formDataToSend.append(key, formData[key]);
            }
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
        await registerUser(formDataToSend);
      }
      onClose();
    } catch (error) {
      console.error(`${isLogin ? 'Login' : 'Registration'} failed:`, error);
      setError(error.message || `An error occurred during ${isLogin ? 'login' : 'registration'}. Please try again.`);
    }
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError('');
  };

  const steps = ['Personal Information', 'Account Details'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box sx={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
              <StyledAvatar src={previewUrl} />
              <label htmlFor="icon-button-file">
                <AvatarInput
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <IconButton 
                  color="primary" 
                  aria-label="upload picture" 
                  component="span"
                  sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    right: 0, 
                    backgroundColor: 'white' 
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </label>
            </Box>
            <StyledTextField
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              fullWidth
              required
            />
            <StyledTextField
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              fullWidth
              required
            />
            <StyledTextField
              label="Date of Birth"
              name="date_of_birth"
              type="date"
              value={formData.date_of_birth}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            />
            <StyledTextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              fullWidth
              required
            />
          </>
        );
      case 1:
        return (
          <>
            <StyledTextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              required
            />
            <StyledTextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
            />
            <StyledTextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end" sx={{ color: '#C8C4C4' }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Overlay>
      <PopupContainer>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#C8C4C4' }}>
          {isLogin ? 'Welcome Back' : 'Create an Account'}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem', marginBottom: '2rem', color: '#C8C4C4' }}>
          {isLogin ? "Let's start Fucking Again" : "Join us and start your journey"}
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2, backgroundColor: 'rgba(211, 47, 47, 0.1)', color: '#C8C4C4' }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          {isLogin ? (
            <>
              <StyledTextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
              <StyledTextField
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end" sx={{ color: '#C8C4C4' }}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Link href="#" underline="none" sx={{ display: 'block', marginBottom: '1rem', color: '#C8C4C4', textAlign: 'right' }}>
                Forget password?
              </Link>
              <StyledButton type="submit" fullWidth>
                Log in
              </StyledButton>
            </>
          ) : (
            <>
              <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: '1rem' }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ color: '#C8C4C4' }}
                >
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <StyledButton type="submit">
                    Register
                  </StyledButton>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ backgroundColor: '#A2252D', color: '#C8C4C4' }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </>
          )}
        </form>
        <Link
          component="button"
          onClick={() => {
            setIsLogin(!isLogin);
            setActiveStep(0);
            setError('');
          }}
          underline="none"
          sx={{ display: 'block', marginTop: '1rem', color: '#C8C4C4' }}
        >
          {isLogin ? "Don't have an account? Create New" : 'Already have an account? Log In'}
        </Link>
      </PopupContainer>
    </Overlay>
  );
};

export default LoginPopup;