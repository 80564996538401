import React, { useState, useEffect } from 'react';
import { useVoiceCall } from '../contexts/VoiceCallContext';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Button,
  Typography,
  Box,
  IconButton,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  Close as CloseIcon,
  Call as CallIcon,
  CallEnd as CallEndIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
} from '@mui/icons-material';

const CallScreen = () => {
  const { callState, respondToCall, endCall, localAudioTrack } = useVoiceCall();
  const [isMuted, setIsMuted] = useState(false);
  const [callDuration, setCallDuration] = useState(0);

  useEffect(() => {
    let interval;
    if (callState.status === 'ongoing') {
      interval = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
    } else {
      setCallDuration(0);
    }
    return () => clearInterval(interval);
  }, [callState.status]);

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleMuteToggle = () => {
    if (localAudioTrack) {
      localAudioTrack.setEnabled(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const renderCallControls = () => {
    switch (callState.status) {
      case 'ringing':
        return (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="success"
              startIcon={<CallIcon />}
              onClick={() => respondToCall(callState.callId, 'accept')}
              sx={{ mr: 2 }}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<CallEndIcon />}
              onClick={() => respondToCall(callState.callId, 'decline')}
            >
              Decline
            </Button>
          </Box>
        );
      case 'ongoing':
        return (
          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton
              color={isMuted ? 'secondary' : 'primary'}
              onClick={handleMuteToggle}
              sx={{ mr: 2 }}
            >
              {isMuted ? <MicOffIcon /> : <MicIcon />}
            </IconButton>
            <IconButton color="error" onClick={endCall}>
              <CallEndIcon />
            </IconButton>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={callState.status !== 'idle'} maxWidth="xs" fullWidth>
      <DialogTitle>
        {callState.status === 'initiating' ? 'Initiating Call...' : 
         callState.status === 'ringing' ? 'Incoming Call' :
         callState.status === 'ongoing' ? 'Ongoing Call' : 'Call Ended'}
        {callState.status !== 'ongoing' && (
          <IconButton
            aria-label="close"
            onClick={endCall}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Avatar
            src={callState.remoteUser?.picture}
            alt={callState.remoteUser?.name}
            sx={{ width: 80, height: 80, mb: 2 }}
          >
            {callState.remoteUser?.name?.charAt(0)}
          </Avatar>
          <Typography variant="h6" gutterBottom>
            {callState.remoteUser?.name}
          </Typography>
          {callState.status === 'ongoing' && (
            <Typography variant="body2" color="text.secondary">
              {formatDuration(callDuration)}
            </Typography>
          )}
        </Box>

        {renderCallControls()}

        {callState.status === 'ended' && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>Call Ended</AlertTitle>
            Your call has ended. Duration: {formatDuration(callDuration)}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CallScreen;