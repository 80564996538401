import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useChatNotification } from "../contexts/NotificationsContext";
import { useNavigate } from "react-router-dom";

const ChatNotificationPopup = () => {
    const { chatRequests, respondToChatRequest } = useChatNotification();
    const [open, setOpen] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (chatRequests.length > 0) {
            setCurrentRequest(chatRequests[0]);
            setOpen(true);
        } else {
            setOpen(false);
            setCurrentRequest(null);
        }
    }, [chatRequests]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setCurrentRequest(null);
    }, []);

    const handleAccept = useCallback(() => {
        if (currentRequest) {
            respondToChatRequest(currentRequest.chat_id, "accept");
            handleClose();
            navigate(`/chat/${currentRequest.chat_id}`, {
                state: { userId: currentRequest.user_id },
            });
        }
    }, [currentRequest, respondToChatRequest, handleClose, navigate]);

    const handleReject = useCallback(() => {
        if (currentRequest) {
            respondToChatRequest(currentRequest.chat_id, "reject");
            handleClose();
        }
    }, [currentRequest, respondToChatRequest, handleClose]);

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                // Only close the dialog if the close reason is not backdropClick or escapeKeyDown
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose();
                }
            }}
            disableEscapeKeyDown
        >
            <DialogTitle>Chat Request</DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1">
                        You have a new chat request from {currentRequest?.user_name}.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReject} color="error">
                    Reject
                </Button>
                <Button onClick={handleAccept} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChatNotificationPopup;
