import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

const VoiceCallContext = createContext();

export const useVoiceCall = () => useContext(VoiceCallContext);

export const VoiceCallProvider = ({ children }) => {
  const [callState, setCallState] = useState({
    status: 'idle',
    callId: null,
    channelName: null,
    agoraToken: null,
    remoteUser: null,
  });

  const [client, setClient] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);

  const socketRef = useRef(null);

  useEffect(() => {
    const agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    setClient(agoraClient);

    return () => {
      agoraClient.leave();
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const ws = new WebSocket(`wss://www.kookutalk.com/ws/voice-call/?token=${token}`);

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socketRef.current = ws;

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  const sendMessage = useCallback((message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected');
    }
  }, []);

  const initiateCall = useCallback((modelId, isFree = false) => {
    setCallState(prev => ({ ...prev, status: 'initiating' }));
    sendMessage({
      action: 'initiate_call',
      model_id: modelId,
      is_free: isFree
    });
  }, [sendMessage]);

  const respondToCall = useCallback((callId, response) => {
    sendMessage({
      action: 'respond_to_call',
      call_id: callId,
      response: response
    });
  }, [sendMessage]);

  const endCall = useCallback(() => {
    if (callState.callId) {
      sendMessage({
        action: 'end_call',
        call_id: callState.callId
      });
    }
    leaveCall();
  }, [sendMessage, callState.callId]);

  const leaveCall = useCallback(async () => {
    if (localAudioTrack) {
      await localAudioTrack.stop();
      await localAudioTrack.close();
    }
    if (client) {
      await client.leave();
    }
    setLocalAudioTrack(null);
    setRemoteAudioTrack(null);
    setCallState({
      status: 'idle',
      callId: null,
      channelName: null,
      agoraToken: null,
      remoteUser: null,
    });
  }, [client, localAudioTrack]);

 const joinCall = useCallback(async () => {
  if (!client || !callState.channelName || !callState.agoraToken) return;

  try {
    await client.join(process.env.REACT_APP_AGORA_APP_ID, callState.channelName, callState.agoraToken);

    // Request microphone access
    const newLocalAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    
    // Publish the audio track to the channel
    await client.publish(newLocalAudioTrack);
    setLocalAudioTrack(newLocalAudioTrack);

    client.on('user-published', async (user, mediaType) => {
      if (mediaType === 'audio') {
        await client.subscribe(user, mediaType);
        setRemoteAudioTrack(user.audioTrack);
        user.audioTrack.play();
      }
    });

    client.on('user-unpublished', (user, mediaType) => {
      if (mediaType === 'audio') {
        setRemoteAudioTrack(null);
      }
    });
  } catch (error) {
    console.error('Failed to join the call or access the microphone:', error);
    alert('Failed to access microphone. Please check your permissions.');
  }
}, [client, callState.channelName, callState.agoraToken]);


  useEffect(() => {
    const handleWebSocketMessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.action) {
        case 'connection_established':
          console.log('WebSocket connection established');
          break;
        case 'call_initiated':
          setCallState(prev => ({
            ...prev,
            status: 'ringing',
            callId: data.call_id,
            remoteUser: {
              id: data.callee_id,
              name: data.callee_name,
              picture: data.callee_picture
            }
          }));
          break;
        case 'incoming_call':
          setCallState(prev => ({
            ...prev,
            status: 'ringing',
            callId: data.call_id,
            remoteUser: {
              id: data.caller_id,
              name: data.caller_name,
              picture: data.caller_picture
            }
          }));
          break;
        case 'call_accepted':
          setCallState(prev => ({
            ...prev,
            status: 'ongoing',
            channelName: data.channel_name,
            agoraToken: data.agora_token
          }));
          joinCall();
          break;
        case 'call_declined':
        case 'call_canceled':
          leaveCall();
          break;
        case 'call_ended':
          if (data.reason === 'insufficient_balance') {
            alert('Call ended due to insufficient balance');
          }
          leaveCall();
          break;
        case 'error':
          console.error(`Error: ${data.error_type} - ${data.error_message}`);
          break;
      }
    };

    if (socketRef.current) {
      socketRef.current.addEventListener('message', handleWebSocketMessage);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.removeEventListener('message', handleWebSocketMessage);
      }
    };
  }, [joinCall, leaveCall]);

  const value = {
    callState,
    initiateCall,
    respondToCall,
    endCall,
    localAudioTrack,
    remoteAudioTrack,
  };

  return (
    <VoiceCallContext.Provider value={value}>
      {children}
    </VoiceCallContext.Provider>
  );
};

export default VoiceCallContext;