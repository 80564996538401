import React, { useContext, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Menu, MenuItem, InputBase, Box, Grid, useMediaQuery, useTheme, Avatar, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AuthContext } from '../../contexts/AuthContext';
import { styled } from '@mui/material/styles';
import PageHeader from './PageHeader';
import WalletIcon from '../../assets/images/WalletIcon.png';
import Coins from '../../assets/images/Coins.png';
import Logo from '../../assets/images/logot.png';
import LoginPopup from '../../Components/LoginPopup';
import SidebarMenu from '../../Components/SidebarMenu';
import PopupMobileMenu from '../../Components/PopupMobileMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: '60px',
  justifyContent: "center",
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 20,
  backgroundColor: '#772728',
  color: '#FFFFFF',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  cursor: 'pointer',
  border: '2px solid #FFDF85',
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 40,
  },
}));

const StyledWalletBalance = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFDF85',
  color: '#000',
  padding: theme.spacing(1.2),
  borderRadius: '30px',
  height: 20,
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  fontSize: '21px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  minWidth: '120px',
}));

const LogoImg = styled('img')(({ theme }) => ({
  height: 'auto',
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    width: '150px',
    paddingLeft: '15px',
  },
}));

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPopupMobileMenuOpen, setIsPopupMobileMenuOpen] = useState(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleAvatarClick = () => {
    if (user) {
      navigate('/user/profile');
    } else {
      setIsLoginPopupOpen(true);
    }
  };

  const closeLoginPopup = () => {
    setIsLoginPopupOpen(false);
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const handlePopupMobileMenuOpen = () => {
    setIsPopupMobileMenuOpen(true);
  };

  const handlePopupMobileMenuClose = () => {
    setIsPopupMobileMenuOpen(false);
  };

  if (location.pathname === '/') {
    return (
      <StyledAppBar position="static">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={4} sm={4}>
              <Box display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-start'} alignItems="center">
                {isMobile && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleSidebarOpen}
                    sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                <Link to="/">
                  <LogoImg src={Logo} alt="Logo" />
                </Link>
              </Box>
            </Grid>
            {!isMobile && (
              <Grid item xs={4} sm={4}>
                <Box display="flex" justifyContent="center">
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search models by name and language"
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search>
                </Box>
              </Grid>
            )}
            <Grid item xs={8} sm={4}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {user ? (
                  <>
                    {!isMobile && (
                      <StyledWalletBalance>
                        <img src={WalletIcon} alt="Wallet Icon" style={{ width: '24px', marginRight: '6px' }} />
                        {user.wallet_balance}
                        <img src={Coins} alt="Coin Icon" style={{ width: '45px', marginLeft: '6px' }} />
                      </StyledWalletBalance>
                    )}
                    {isMobile && (
                      <IconButton
                        color="inherit"
                        onClick={handlePopupMobileMenuOpen}
                        sx={{ mr: 1 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                    <StyledAvatar
                      alt={user.username}
                      src={user.profile_picture ? `${process.env.REACT_APP_API_URL}${user.profile_picture}` : ''}
                      onClick={handleAvatarClick}
                    />
                  </>
                ) : (
                  <>
                    {isMobile && (
                      <IconButton
                        color="inherit"
                        onClick={handlePopupMobileMenuOpen}
                        sx={{ mr: 1 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                    <StyledAvatar onClick={handleAvatarClick} />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            {user ? (
              <>
                <MenuItem component={Link} to="/chat" onClick={handleClose}>
                  Chat
                </MenuItem>
                <MenuItem component={Link} to="/models" onClick={handleClose}>
                  Models
                </MenuItem>
                <MenuItem component={Link} to="/user/profile" onClick={handleClose}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </>
            ) : (
              <>
                <MenuItem component={Link} to="/signup" onClick={handleClose}>
                  Create New Account
                </MenuItem>
                <MenuItem component={Link} to="/login" onClick={handleClose}>
                  Login
                </MenuItem>
              </>
            )}
           </Menu>
        </Toolbar>

        {isLoginPopupOpen && <LoginPopup onClose={closeLoginPopup} />}
        <SidebarMenu isOpen={isSidebarOpen} onClose={handleSidebarClose} />
        <PopupMobileMenu isOpen={isPopupMobileMenuOpen} onClose={handlePopupMobileMenuClose} />
      </StyledAppBar>
    );
  } else {
    return <PageHeader title={location.pathname.slice(1).replace(/\//g, ' ')} />;
  }
};

export default Header;