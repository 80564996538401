// src/components/common/Footer.js
import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#333', color: '#fff', padding: '2rem 0', marginTop: '2rem' }}>
      <Container>
        <Box textAlign="center">
          <Typography variant="h6" gutterBottom>
            Chat App
          </Typography>
          <Typography variant="body2" paragraph>
            &copy; 2024 Chat App. All rights reserved.
          </Typography>
          <Typography variant="body2">
            <a href="https://www.example.com/privacy" style={{ color: '#fff', textDecoration: 'none' }}>
              Privacy Policy
            </a>{' | '}
            <a href="https://www.example.com/terms" style={{ color: '#fff', textDecoration: 'none' }}>
              Terms of Service
            </a>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
