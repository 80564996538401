import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { getUserProfile, updateUserProfile } from '../../services/api';
import { Box, Typography, Avatar, CircularProgress, Container, Button, Grid, Paper, Tabs, Tab, TextField, Snackbar, IconButton, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginRight: theme.spacing(3),
    marginBottom: 0,
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
}));

const ProfileInfo = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  color: '#fff',
  '&:hover': {
    background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.main} 90%)`,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const UserProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { user, logout } = useContext(AuthContext);

  const mockActivityData = [
    { date: '2024-01-01', activity: 5 },
    { date: '2024-01-02', activity: 8 },
    { date: '2024-01-03', activity: 12 },
    { date: '2024-01-04', activity: 7 },
    { date: '2024-01-05', activity: 15 },
    { date: '2024-01-06', activity: 10 },
    { date: '2024-01-07', activity: 9 },
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfile();
        setProfile(response.data);
        setEditedProfile(response.data);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      await updateUserProfile(editedProfile);
      setProfile(editedProfile);
      setEditMode(false);
      setSnackbarMessage('Profile updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update profile:', error);
      setSnackbarMessage('Failed to update profile');
      setSnackbarOpen(true);
    }
  };

  const handleCancel = () => {
    setEditedProfile(profile);
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    setEditedProfile({ ...editedProfile, [e.target.name]: e.target.value });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6">Failed to load profile data.</Typography>
      </Box>
    );
  }

  return (
    <ProfileContainer maxWidth="md">
      <ProfilePaper elevation={3}>
        <ProfileHeader>
          <ProfileAvatar alt={profile.username} src={profile.profile_picture ? `http://10.35.168.104/
${profile.profile_picture}` : ''} />
          <ProfileDetails>
            <Typography variant="h5" fontWeight="bold">
              {profile.username}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {profile.email}
            </Typography>
          </ProfileDetails>
        </ProfileHeader>

        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          indicatorColor="primary" 
          textColor="primary" 
          centered
          variant={isMobile ? "fullWidth" : "standard"}
        >
          <StyledTab label="Profile" />
          <StyledTab label="Activity" />
          <StyledTab label="Settings" />
        </Tabs>

        {tabValue === 0 && (
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {editMode ? (
                  <TextField
                    fullWidth
                    name="bio"
                    label="Bio"
                    multiline
                    rows={4}
                    value={editedProfile.bio || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                  />
                ) : (
                  <ProfileInfo variant="body2">
                    <strong>Bio:</strong> {profile.bio || 'No bio available'}
                  </ProfileInfo>
                )}
                <ProfileInfo variant="body2">
                  <strong>Wallet Coins:</strong> {profile.wallet_balance}
                </ProfileInfo>
              </Grid>
              <Grid item xs={12} sm={6}>
                {editMode ? (
                  <TextField
                    fullWidth
                    name="location"
                    label="Location"
                    value={editedProfile.location || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                  />
                ) : (
                  <ProfileInfo variant="body2">
                    <strong>Location:</strong> {profile.location || 'No location provided'}
                  </ProfileInfo>
                )}
                <ProfileInfo variant="body2">
                  <strong>Online Status:</strong> {profile.is_online ? 'Online' : 'Offline'}
                </ProfileInfo>
              </Grid>
            </Grid>
            {editMode ? (
              <Box mt={2} display="flex" justifyContent="center">
                <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSave} style={{ marginRight: theme.spacing(2) }}>
                  Save
                </Button>
                <Button startIcon={<CancelIcon />} variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box mt={2} display="flex" justifyContent="center">
                <Button startIcon={<EditIcon />} variant="outlined" color="primary" onClick={handleEdit}>
                  Edit Profile
                </Button>
              </Box>
            )}
          </Box>
        )}

        {tabValue === 1 && (
          <Box mt={3} height={300}>
            <Typography variant="h6" gutterBottom align="center">User Activity</Typography>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={mockActivityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="activity" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        )}

        {tabValue === 2 && (
          <Box mt={3}>
            <Typography variant="h6" gutterBottom align="center">Account Settings</Typography>
            {/* Add account settings options here */}
          </Box>
        )}

        <LogoutButton variant="contained" onClick={logout} fullWidth>
          Logout
        </LogoutButton>
      </ProfilePaper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </ProfileContainer>
  );
};

export default UserProfile;