import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useTheme, styled } from '@mui/material/styles';
import { Box, TextField, Button, Typography, Container, Link, useMediaQuery, IconButton, InputAdornment, Alert } from '@mui/material';  // <-- Added Alert here
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// Import the background image
import backgroundImage from '../../assets/images/girls-dancing-club.jpg';

const BackgroundContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(2),
  width: '100%',
  maxWidth: '100%',
  margin: 0,
}));

const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
  borderRadius: '16px',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '400px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    fontSize: '1.2rem',
  },
  '& .MuiInputBase-input': {
    fontSize: '1rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: '#fff',
  fontSize: '1rem',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
  },
}));


const StyledLink = styled(Link)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  display: 'block',
  textAlign: 'center',
  fontSize: '1rem',
}));

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { loginUser } = useContext(AuthContext) || {};
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    try {
      await loginUser(email, password);
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.message || 'An error occurred during login. Please try again.');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <BackgroundContainer>
      <StyledCard>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Welcome Back!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Our site is for dating and chatting with Indian models.
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <StyledTextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
          />
          <StyledTextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledButton type="submit" fullWidth variant="contained">
            Sign In
          </StyledButton>
        </form>
        <StyledLink href="/signup" variant="body2">
          Don't have an account? Sign Up
        </StyledLink>
      </StyledCard>
    </BackgroundContainer>
  );
};

export default Login;
